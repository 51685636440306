import { Injectable } from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import { DataService } from '../../@core/utils';

@Injectable({
  providedIn: 'root',
})
export class OrderOptimizationService {

  displayIdGeoJSON = {};
  fetchingRoutePromises = {};


  constructor(private http:DataService,private httpDirect: HttpClient, handler: HttpBackend) {
    this.httpDirect = new HttpClient(handler);
  }

  async fetchRoute(coordinates): Promise<any> {
    return this.httpDirect.post(
        'https://prod-s2.track360.net.in/api/v1/auth/calculate_eta_route', {
          coordinates,
          radiuses:[-1]
        }).toPromise();
  }


  async getRoute(batch) {
    if (this.fetchingRoutePromises.hasOwnProperty(batch.display_id)) {
      await this.fetchingRoutePromises[batch.display_id];
    }
    if (this.displayIdGeoJSON.hasOwnProperty(batch.display_id)) {
      return this.displayIdGeoJSON[batch.display_id];
    }
    const x = [];
    batch.map_data.forEach(coords => {
      x.push([coords[1], coords[0]]);
    });
    this.displayIdGeoJSON[batch.display_id] = await this.fetchRoute(x);
    return this.displayIdGeoJSON[batch.display_id];
  }
  
  async getOrderFromDB(){
    return await this.http.query({}, "get_multi_order_from_db", "auth");
  }


  async getOutletGeom(outlets:any){
    return await this.http.createDirect(
      outlets,
      {},
      "get_outlet_geom",
      "auth"
    );  }

}
