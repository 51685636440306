import {Injectable} from '@angular/core';
import {HttpService} from '../../config/http.service';
import {HttpClient} from '@angular/common/http';
import {NbAuthService} from '@nebular/auth';
import {BehaviorSubject, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {Auth, Status} from '../models';
import {MessagingService} from './messaging.service';


@Injectable({
    providedIn: 'root',
})
export class UserService extends HttpService<any> {
    user: Auth = {} as Auth;
    statuses: Status[] = [];
    companyConfig: any;
    checkUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    user$: Subject<any> = new Subject() as Subject<any>;
    statuses$: Subject<any> = new Subject() as Subject<any>;

    constructor(private httpClient: HttpClient, private router: Router,
                private nbAuth: NbAuthService, private messagingService: MessagingService) {
        super(httpClient, {
            path: '/user',
        });
        this.nbAuth.getToken().subscribe((res) => {
            const identity = res.getPayload()['identity'];
            this.init(identity).then();
        });
    }

    async init(identity: any) {
        try {
            this.user = identity;
            try {
                this.companyConfig = await this.getCompanyConfig();
                this.user.config = this.companyConfig;
            } catch (e) {
                console.error(e);
            }
            this.user$.next(this.user);
            this.statuses = await this.getStatuses();
            this.statuses$.next(this.statuses);
            this.messagingService.requestPermission(this.user.user.id);
            this.checkUser.next(true);
        } catch (e) {
            console.error(e);
            this.logOut();
        }
    }


    async getStatuses(): Promise<Status[]> {
        return (await this.query({__order_by: 'code'}, 'status', 'order')).data;
    }

    async getCompanyConfig() {
        return (await this.query({
            __company_id__equal: this.user.company_id,
            __only: ['key', 'value'],
        }, 'company_config', 'auth')).data;
    }

    logOut() {
        this.nbAuth.logout('email').subscribe(() => {
            localStorage.removeItem('auth_app_token');
            // this.router.navigate(['auth']).then();
            location.reload();
        }, () => {
            localStorage.removeItem('auth_app_token');
            // this.router.navigate(['auth']).then();
            location.reload();
        });
    }
}
