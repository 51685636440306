import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filter',
})
export class FilterPipe implements PipeTransform {

    transform(items: any[], fields: string[], value: any): any[] {

        if (!items) return [];
        if (!value) return items;

        const it = fields.map(field => {
            return items.filter(str => {
                if (field ===  'mobile_number') {
                    return str[field].includes(value);

                } else {
                    return str[field].toLowerCase().includes(value.toLowerCase());
                }

            });
        });
        const filterItem = [];

        for (const searchItem of it) {
           for (const item of searchItem) {
               filterItem.push(item);
           }
        }
        return filterItem;
    }
}
