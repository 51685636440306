import {AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DataService, ToastService} from '../../../@core/utils';
import {Outlet} from '../../../@core/models';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
@Component({
    selector: 'ngx-outlet-list',
    templateUrl: './outlet-list.component.html',
    styleUrls: ['./outlet-list.component.scss'],
})
export class OutletListComponent implements OnInit, AfterViewInit, OnChanges {

    @Input()
    serial: string = '1';
    @Input()
    id: string;
    @Input()
    brandId: string;
    @Input()
    type: string;

    filters: any = {__only: ['id', 'name', 'mobile_number'], __active__bool: 'true'};
    page: number = 1;
    outlets: Outlet[] = [];

    constructor(private translate: TranslateService,private http: DataService, private toaster: ToastService, private router: Router, private cd: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.cd.detectChanges();
        this.filters.__brand_id__equal = this.brandId;
    }
    translateText(key: string): string {
		let translation: string;
		this.translate.get(key).subscribe((res: string) => {
			translation = res;
		});
		return translation;
	}
    ngOnChanges(changes: SimpleChanges): void {
        if ((changes.hasOwnProperty('id') && changes.id?.currentValue) || changes.brandId?.currentValue) {
            this.filters['__brand_id__equal'] = changes.brandId?.currentValue;
            this.getOutlets().then();
        }
    }

    ngAfterViewInit(): void {
        this.filters['__' + this.type + '__ne'] = this.id;
        this.filters['__brand_id__equal'] = this.brandId;
        if (this.id) {
            this.getOutlets().then();
        }
    }


    async getOutlets() {
        try {
            const query = {__page: this.page, __only: ['id', 'name', 'brand']};
            query['__brand_id__equal'] = this.brandId;
            query['__' + this.type + '__equal'] = this.id;
            this.outlets = (await this.http.query(query, 'outlet', 'auth')).data;
        } catch (e) {
            console.error(e);
        }
    }

    async addOutlet(event) {
        if (this.type !== 'merchant_id') {
            const data = {};
            data[this.type] = this.id;
            // data[''] = this.id;
            try {
                await this.http.update(event.id, data, {}, 'outlet', 'auth');
                this.outlets.push(event);
                this.toaster.showToast(this.translateText('Successfully added outlet'), 'Success', false);
            } catch (e) {
                this.toaster.showToast(this.translateText('Error adding outlet'), 'Error', true, e);
            }
        } else {
            const data = {outlet_id: event.id, merchant_id: this.id, __action: 'add'};
            try {
                await this.http.create(data, {}, 'merchant_outlet_association', 'auth');
                this.outlets.push(event);
                this.toaster.showToast(this.translateText('Successfully added outlet'), 'Success', false);
            } catch (e) {
                this.toaster.showToast(this.translateText('Error adding outlet'), 'Error', true, e);
            }
        }

    }


    async removeOutlet(id: any, index: number) {
        if (this.type !== 'merchant_id') {
            const data = {};
            data[this.type] = null;
            try {
                await this.http.update(id, data, {}, 'outlet', 'auth');
                this.outlets.splice(index, 1);
                this.toaster.showToast(this.translateText('Successfully removed outlet'), 'Success', false);
            } catch (e) {
                this.toaster.showToast(this.translateText('Error removing outlet'), 'Error', true, e);
            }
        } else {
            const data = {outlet_id: id, merchant_id: this.id, __action: 'remove'};
            try {
                await this.http.create(data, {}, 'merchant_outlet_association', 'auth');
                this.outlets.splice(index, 1);
                this.toaster.showToast(this.translateText('Successfully added outlet'), 'Success', false);
            } catch (e) {
                this.toaster.showToast(this.translateText('Error adding outlet'), 'Error', true, e);
            }
        }
    }

    editOutlet(id?: any) {
        this.router.navigate(['/pages/management/base/outlet/' + (id ? id.toString(10) : 'new')]);
    }

    prevPage() {
        this.page -= 1;
        this.getOutlets().then();
    }

    nextPage() {
        this.page += 1;
        this.getOutlets().then();
    }
}
