import {Injectable} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/messaging';

import {BehaviorSubject} from 'rxjs';
import {DataService} from './data.service';

@Injectable({
	providedIn: 'root',
})
export class MessagingService {

	currentMessage = new BehaviorSubject({});
	public audio: HTMLAudioElement;


	constructor(
		private angularFireMessaging: AngularFireMessaging,
		private http: DataService) {
		this.angularFireMessaging.messages.subscribe(
			(messaging: AngularFireMessaging) => {
				messaging.onMessage = messaging.onMessage.bind(messaging);
				messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
			},
		);
	}

	/**
	 * update token in firebase database
	 *
	 * @param userId userId as a key
	 * @param token token as a value
	 */
	async updateToken(userId, token) {
		// we can change this function to request our backend service
		await this.http.update(userId, {device_token: token}, {
			__include: [],
			__only: ['id', 'device_token'],
		}, 'user', 'auth');
	}

	/**
	 * request permission for notification from firebase cloud messaging
	 *
	 * @param userId userId
	 */
	requestPermission(userId) {
		this.angularFireMessaging.requestToken.subscribe(
			(token) => {
				this.updateToken(userId, token);
			},
			(err) => {
				console.error(err);
			},
		);
	}

	/**
	 * hook method when new notification received in foreground
	 */
	receiveMessage() {
		// const self = this;
		this.angularFireMessaging.messages.subscribe(
			(payload) => {
				this.currentMessage.next(payload);
			});
	}
}
