import {Injectable, OnDestroy} from '@angular/core';
import {interval, Subject, Subscription} from 'rxjs';
import * as moment from 'moment';
import {DataService} from './data.service';

@Injectable()
export class OrderService implements OnDestroy {

    orders$: Subject<{ orders: any[], reset: boolean }> = new Subject() as Subject<{ orders: any[], reset: boolean }>;
    public lastUpdatedOn: Date = new Date();
    sub: Subscription;
    today: Date = new Date();
    query: any = {};
    orderList: any[] = [];
    serviceLoading = false;
    countDown: number = 10;
    private initial = true;

    constructor(private http: DataService) {
        this.today.setHours(this.today.getHours() - 124);
    }

    resetTime() {
        if (this.query.hasOwnProperty('__created_on__datetime_gte')) {
            this.lastUpdatedOn = moment(this.query['__created_on__datetime_gte']).utc().toDate();
        } else {
            this.lastUpdatedOn.setHours(this.lastUpdatedOn.getHours() - 24);
        }
    }

    async init(query?: any, reset?: boolean) {
        if (query) {
            this.query = query;
        } else {
            this.query = {};
        }
        this.resetTime();
        this.destroy();
        await this.getOrders(reset);
        this.sub = interval(10000)
            .subscribe(async (_res) => {
                this.countDown = 10;
                await this.getOrders();
            });
    }

    async getOrders(reset?: boolean) {
        const currentTime: Date = new Date();
        currentTime.setMinutes(currentTime.getMinutes() - 1);
        try {
            const query = {
                last_updated_on:  this.lastUpdatedOn.toJSON(),
                __only: ['id', 'external_id', 'created_on', 'updated_on', 'order_undeliver',
                    'customer', 'outlet', 'delivery_address', 'rider', 'rider_id', 'is_acknowledged', 'rider_count',
                    'status_code', 'status_name', 'order_number', 'is_express', 'store_id', 'tip_amount', 'type',
                    'payment_type', 'sub_total', 'zone_name', 'scheduled_for', 'assignment_retry'],
                __limit__equal: 1000,
                __order_by: '-updated_on',
            };
            let deliveryaddress=null;
            let pickupaddress=null;
            for (const queryKey in this.query) {
                if (this.query.hasOwnProperty(queryKey)) {
                    if(queryKey==='pickupaddress'){
                        pickupaddress=this.query[queryKey];
                    }else if(queryKey==='deliveryaddress'){
                        deliveryaddress=this.query[queryKey];
                    }else{
                        query[queryKey] = this.query[queryKey];
                    }
                }
            }
            if (this.lastUpdatedOn) {
                query['last_updated_on'] = this.lastUpdatedOn.toJSON();
            }
            const res = await this.http.query(query, 'order_redis', 'order');
            this.lastUpdatedOn = currentTime;
            this.initial = false;
            if(pickupaddress){
                res.orders=await this.searchOrders(pickupaddress, res.orders);
            }
            if(deliveryaddress){
                res.orders=await this.searchOrders(deliveryaddress, res.orders);
            }
            this.orders$.next({orders: res.orders, reset: reset});
            return;
        } catch (e) {
            console.error(e,111);
        }
    }

    async searchOrders(searchText, orders) {
        const matchingOrders = [];
        for (const order of orders) {
            const pickUpAddress = Object.keys(order.pick_up_address).length!=0 ? (order.pick_up_address?.google_address).toLowerCase() : ""; 
            const deliveryAddress = Object.keys(order.delivery_address).length!=0 ? (order.delivery_address?.google_address).toLowerCase() : ""; 
            searchText = searchText.toLowerCase(); 
            if (pickUpAddress.includes(searchText) || deliveryAddress.includes(searchText)) {
                matchingOrders.push(order); // Add the matching order to the result array
            }
        }
        return matchingOrders;
    }
    

    setOrderList(list) {
        this.orderList = list;
    }

    destroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    ngOnDestroy(): void {
        this.destroy();
    }

}
