import {AfterViewInit, ChangeDetectorRef, Component, Input} from '@angular/core';
import {DataService, ToastService} from '../../../@core/utils';
import {Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: 'ngx-user-list',
	templateUrl: './user-list.component.html',
	styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements AfterViewInit {

	@Input()
	serial: string = '1';
	@Input()
	zoneId: string;

	users: any[] = [];
	filters: any;
	infoPopup = this.translateText('If unable to Add Users, Create Zone First (Step 1)');

	constructor(private http: DataService, private toaster: ToastService, private router: Router, private cd: ChangeDetectorRef,
				private translate: TranslateService) {
	}

	translateText(key: string): string {
		let translation: string;
		this.translate.get(key).subscribe((res: string) => {
			translation = res;
		});
		return translation;
	}

	ngAfterViewInit(): void {
		this.filters = {__only: ['id', 'name', 'mobile_number'], __zone_id__ne: this.zoneId, __active__bool: true};
		if (this.zoneId) {
			this.getUsers().then();
		}
		this.cd.detectChanges();
	}

	async getUsers() {

		try {
			const query = {
				__only: ['id', 'name'], __zone_id__equal: this.zoneId,
				user: true, __limit: 100,
			};
			const users = await this.http.query(query, 'user', 'auth');
			if (users.data && users.data.length) {
				this.users = users.data;
			} else {
				this.users = [];
			}
		} catch (e) {
		}

	}

	editUser(id?: number) {
		this.router.navigate(['/pages/management/base/user/' + (id ? id : 'new')]);
	}

	async addUser(event) {
		this.cd.detectChanges();

		try {
			await this.http.create({
				__action: 'add',
				user_id: event.id,
				zone_id: this.zoneId,
			}, {}, 'user_zone', 'auth');
			this.users.push(event);
			this.toaster.showToast('Successfully added user.', 'Success', false);
		} catch (e) {
			this.toaster.showToast('Error adding user ', 'Error', true, e);
		}


	}

	async removeUser(id: number, index: number) {

		try {
			await this.http.create({
				__action: 'remove',
				user_id: id,
				zone_id: this.zoneId,
			}, {}, 'user_zone', 'auth');
			this.users.splice(index, 1);
			this.toaster.showToast('Successfully removed user.', 'Success', false);
		} catch (e) {
			this.toaster.showToast('Error removing user ', 'Error', true, e);
		}
	}
}
