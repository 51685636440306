import {Component, Input, OnInit} from '@angular/core';
import {DataService, ToastService} from '../../../@core/utils';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ngx-approve-card',
  templateUrl: './approve-card.component.html',
  styleUrls: ['./approve-card.component.scss'],
})
export class ApproveCardComponent implements OnInit {


  @Input() serial: string;
  @Input() externalId: string;
  loading = true;
  spinnerMessage = 'Loading...';

  approval: any;
  notes = '';
  approvalList = [];

  constructor(private translate: TranslateService
,    private http: DataService, private toaster: ToastService) {
  }

  ngOnInit() {
    this.getApproval().then();
  }
  translateText(key: string): string {
		let translation: string;
		this.translate.get(key).subscribe((res: string) => {
			translation = res;
		});
		return translation;
	}
  async getApproval() {
    this.loading = true;
    try {
      if (this.externalId) {
        this.approvalList = (await this.http.query({
              __order_by: '-created_on', __external_id__equal: this.externalId, __limit: 5
            },
            'approval', 'auth')).data;
        if (this.approvalList[0].status === 'PENDING') {
          this.approval = this.approvalList[0];
          this.notes = this.approval.notes;
          this.approvalList.shift();
        } else {
          this.approval = null;
        }
      }
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }

  async approve() {
    this.loading = true;
    try {
      await this.http.update(this.approval.id, {status: 'APPROVED', notes: this.notes}, {}, 'approval', 'auth');
      this.toaster.showToast(this.translateText('Request approved !' ), 'Success', false);
    } catch (e) {
      this.toaster.showToast(this.translateText('Error approving request !' ), 'Error', true, e);
      console.error(e);
    }
    this.getApproval().then();
    this.loading = false;
  }

  async reject() {
    this.loading = true;
    try {
      await this.http.update(this.approval.id, {status: 'REJECTED', notes: this.notes}, {}, 'approval', 'auth');
      this.toaster.showToast(this.translateText('Request rejected !' ), 'Success', false);
    } catch (e) {
      this.toaster.showToast(this.translateText('Error rejecting request!' ), 'Error', true, e);
      console.error(e);
    }
    this.getApproval().then();
    this.loading = false;
  }
}
