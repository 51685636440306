import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeToHoursMinutes'
})
export class TimeToHoursMinutesPipe implements PipeTransform {
  transform(value: number): string {
    if (value === null || value === undefined || value < 0) {
      return '';
    }
    const totalMinutes = Math.round(value); 
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours} hrs ${minutes} min`;
  }
}