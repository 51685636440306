import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import {NB_AUTH_OPTIONS, NbAuthService, NbResetPasswordComponent} from '@nebular/auth';
import {Router} from '@angular/router';
import {ConstantsService} from '../../@core/utils/constants.service';

@Component({
	selector: 'ngx-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent extends NbResetPasswordComponent {

	showPassword = false;
	showCPassword = false;

	constructor(service: NbAuthService, @Inject(NB_AUTH_OPTIONS) options: {}, cd: ChangeDetectorRef, router: Router,
				public constantsService: ConstantsService ) {
		super(service, options, cd, router);
	}

    updateLogoURL($event) {
		$event.target.src = this.constantsService.roadcastURL;
    }

	getInputType() {
		if (this.showPassword) {
			return 'text';
		}
		return 'password';
	}

	toggleShowPassword() {
		this.showPassword = !this.showPassword;
	}

	getCInputType() {
		if (this.showCPassword) {
			return 'text';
		}
		return 'password';
	}

	toggleShowCPassword() {
		this.showCPassword = !this.showCPassword;
	}

}