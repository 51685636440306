import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import {NB_AUTH_OPTIONS, NbAuthService, NbRegisterComponent} from '@nebular/auth';
import {ConstantsService} from '../../@core/utils/constants.service';
import {Router} from '@angular/router';

@Component({
	selector: 'ngx-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss'],
})
export class RegisterComponent extends NbRegisterComponent {
	sliderUrl: any;
	constructor(service: NbAuthService, @Inject(NB_AUTH_OPTIONS) options: {}, cd: ChangeDetectorRef, router: Router,
				public constantsService: ConstantsService ) {
		super(service, options, cd, router);
		this.sliderUrl={
			'slider1':this.constantsService.login_slide_1,
			'slider2':this.constantsService.login_slide_2,
			'slider3':this.constantsService.login_slide_3
		}
	}

    updateLogoURL($event) {
		$event.target.src = this.constantsService.roadcastURL;
    }
}
