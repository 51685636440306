import {Component, Input, OnInit} from '@angular/core';
import {DataService, ToastService} from '../../../@core/utils';
import {Location} from '@angular/common';

@Component({
  selector: "ngx-permission",
  templateUrl: "./permission.component.html",
  styleUrls: ["./permission.component.scss"],
})
export class PermissionComponent implements OnInit {
  @Input()
  userId: string = null;
  permissions: any[] = [];
  @Input()
  userPermissions: any[] = [];
  isPermission: boolean = false;
  
  constructor(
    private http: DataService,
    private toaster: ToastService,
    private _location: Location
  ) {}

  ngOnInit() {
    this.getPermission().then();
    if (!this.userPermissions) this.userPermissions = [];
  }

  async getPermission() {
    try {
      this.permissions = (await this.http.query({}, "permission", "auth")).data;
      this.permissions.forEach((permission) => {
        const userPermissionId = this.userPermissions.find(
          (userPermission) => userPermission.permission_id === permission.id
        );
        if (userPermissionId) {
          permission.active = true;
          permission.updates = userPermissionId;
        } else {
          permission.active = false;
          permission.updates = {
            create: false,
            delete: false,
            read: false,
            update: false,
            user_id: this.userId,
            permission_id: permission.id,
          };
        }
      });
    } catch (e) {}
  }

  async save() {}

  async cancel() {
    this._location.back();
  }

  checkPermission(id: string, name?: string) {
    try {
      const perm = this.userPermissions.find((p) => p.permission_id === id);
      if (perm) {
        return name ? perm[name] : true;
      }
    } catch (e) {
      return false;
    }
  }

  async addPermission(value: boolean, id: string, name?: string) {
    if (name) {
      const data = {
        __action: "update",
        user_id: this.userId,
        permission_id: id,
        updates: {},
      };
      data["updates"][name] = value;
      await this.http.create(data, {}, "user_permission", "auth");
      const perm = this.userPermissions.findIndex((p) => p.permission === id);
    } else {
      if (value) {
        await this.http.create(
          {
            __action: "add",
            user_id: this.userId,
            permission_id: id,
          },
          {},
          "user_permission",
          "auth"
        );
        this.userPermissions.push({
          permission: id,
          read: false,
          write: false,
          delete: false,
          update: false,
        });
      } else {
        await this.http.create(
          {
            __action: "remove",
            user_id: this.userId,
            permission_id: id,
          },
          {},
          "user_permission",
          "auth"
        );
        const perm = this.permissions.findIndex((p) => p.id === id);
        if (perm != -1) {
          this.permissions[perm].updates = {
            create: false,
            delete: false,
            read: false,
            update: false,
            user_id: this.userId,
            permission_id: id,
          };
        }

        //this.userPermissions.splice(perm, 1);
      }
    }
  }

}
