import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as L from 'leaflet';
import {icon, marker} from 'leaflet';
import {DataService} from '../../@core/utils';
import {ActivatedRoute} from '@angular/router';
import {ajax} from 'rxjs/ajax';
import {NbDialogService} from '@nebular/theme';
import {DialogComponent} from '../../@theme/components';
import {environment} from '../../../environments/environment';
import 'leaflet.gridlayer.googlemutant';

@Component({
	selector: 'ngx-share-trip',
	templateUrl: './share-trip.component.html',
	styleUrls: ['./share-trip.component.scss'],
})
export class ShareTripComponent implements OnInit, AfterViewInit {
	map: L.Map;
	layers: any;
	fitBounds: any;
	lat: number;
	lng: number;
	destinationLat: any = null;
	destinationLng: any = null;
	destinationAddress: any = null;
	sourceAddress: any = null;
	sourceLat: any = null;
	sourceLng: any = null;
	latLngsArray: any[];
	markers: any[];
	userName: string;
	outletName: string;
	userPhone: string;
	eta: string;
	distance: string;
	useGoogleMap: any = false;
	timer: any = null;

	options : any;

	constructor(private http: DataService, private route: ActivatedRoute, private dialogService: NbDialogService) {
		this.map = null;
		this.latLngsArray = [];
		this.markers = [];
	}

	ngOnInit() {
		this.initializeMapOptions();
		document.getElementById('nb-global-spinner').remove();
		this.route.queryParams.subscribe(params => {
			const key = params.key;
			this.addDataOnMap(key).then();
			this.timer = setInterval(() => {
				this.addDataOnMap(key).then();
			}, 15000);
		});
	}

	initializeMapOptions(): void {
		this.useGoogleMap = JSON.parse(localStorage.getItem('google_map_config'));
		this.options = {
		  layers: [
			this.useGoogleMap
			  ? (L.gridLayer as any).googleMutant({ type: 'roadmap' }) // Google Maps Roadmap
			  : L.tileLayer(
				  'https://1.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?apiKey=' + environment.hereApiKey,
				  {
					attribution: '',
					maxZoom: 18,
					minZoom: 2,
				  }
				), // Here Maps
		  ],
		  zoomControl: true,
		  fullscreenControl: true,
		  center: L.latLng(28.7163, 77.1563),
		  zoom: 10,
		};
	  }

	async addDataOnMap(key) {
		try {
			let order = await this.http.query({
				key: key,
			}, 'share_details');
			order = order.meta.data;
			this.userName = order.user_name;
			this.userPhone = order.user_phone;
			this.destinationLat = order.destination_lat;
			this.destinationLng = order.destination_lng;
			this.destinationAddress = order.destination_address;
			this.sourceLat = order.pickup_lat;
			this.sourceLng = order.pickup_lng;
			this.sourceAddress = order.pickup_address;
			this.lat = order.rider_lat;
			this.lng = order.rider_lng;
			this.outletName = order.outlet_name;
			const mapOptions = {
				icon: icon({
					iconSize: [18, 46],
					iconAnchor: [13, 46],
					iconUrl: 'assets/images/onduty.png',
				}),
			};
			const mapCustomerOptions = {
				icon: icon({
					iconSize: [40, 40],
					iconAnchor: [20, 40],
					iconUrl: 'assets/images/customer.png',
				}),
			};
			const mapMerchantOptions = {
				icon: icon({
					iconSize: [25, 25],
					iconAnchor: [20, 40],
					iconUrl: 'assets/images/merchant.png',
				}),
			};
			this.layers = [
				marker([this.destinationLat, this.destinationLng], {
					...mapMerchantOptions,
					title: `Destination:${this.destinationAddress}`,
				}).bindTooltip('Drop', {permanent: true, direction: 'bottom'}),
				marker([this.sourceLat, this.sourceLng], {...mapCustomerOptions, title: `Source:${this.sourceAddress}`})
					.bindTooltip('Pickup', {permanent: true, direction: 'bottom'}),
				marker([this.lat, this.lng], {...mapOptions, title: `Rider`}),
			];
			this.fitBounds = [
				[this.destinationLat, this.destinationLng], [this.sourceLat, this.sourceLng], [this.lat, this.lng],
			];

		} catch (e) {
			clearInterval(this.timer);
			this.dialogService.open(DialogComponent, {
				context: {
					title: 'Order Delivered.',
					body: 'Order has been delivered successfully.',
					type: 'Okay',
				},
			});
		}

		try {
			const res = await this.getETA([this.sourceLat, this.sourceLng].join(','), [this.destinationLat, this.destinationLng].join(','));
			this.distance = res.response.route[0].summary.distance;
			this.eta = Math.round(res.response.route[0].summary.travelTime / 60).toString();
		} catch (e) {

		}
	}

	async getETA(start: string, end: string): Promise<any> {
		return new Promise(resolve => ajax.get('https://route.api.here.com/routing/7.2/calculateroute.json?app_id=3q6YMiPOxqoVKQ1BRjAd' +
			'&app_code=ODDcu6rXVrwomGzCToqfTg&waypoint0=geo!' + start + '&waypoint1=geo!' + end +
			'&mode=fastest;car;traffic:enabled', {}).subscribe(res => resolve(res.response)));
	}

	onMapReady(map: L.Map) {
		this.map = map;
		// this.getCurrentPosition();
	}

	ngAfterViewInit(): void {
		// this.map = L.map('map', this.options);
		// this.map.fitBounds(this.fitBounds);
		// this.map.addLayer(this.layers);
	}
}
