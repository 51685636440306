import {Component, Input} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
	selector: 'ngx-one-column-layout',
	styleUrls: ['./one-column.layout.scss'],
	template: `
    <nb-layout>
      <nb-layout-header fixed>
        <ngx-header [newNotification]="newNotification"></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive start state="compacted" fixed="false">
		  <div class="mb-user">
			  <div class="d-flex align-items-center pl-15">
				  <img src="./assets/images/img_placeholder.png" alt="" class="p-pic">
				  <p class="company-name"><span class="font14 bold">Roadcast Test</span><span class="font12">Roadcast Test</span></p>
			  </div>
			  <div class="mt-15 buttons"><a href="" class="font12">Account</a><a href="" class="font12">Logout</a></div>
		  </div>
        <ng-content select="nb-menu"></ng-content>
		  <div class="version-m font10 normal text-center pb-10 black-color bold">v{{currentApplicationVersion}}</div>
      </nb-sidebar>

      <nb-layout-column class="pagepad">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

<!--      <nb-layout-footer fixed>-->
<!--        <ngx-footer></ngx-footer>-->
<!--      </nb-layout-footer>-->
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
	@Input() newNotification: any;
	currentApplicationVersion = environment.appVersion;
}
