import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {DataService, ToastService} from '../../../@core/utils';
import {ActivatedRoute, Router} from '@angular/router';
import {Zone} from '../../../@core/models';
import {TranslateService} from '@ngx-translate/core';
@Component({
    selector: 'ngx-zone-list', templateUrl: './zone-list.component.html', styleUrls: ['./zone-list.component.scss'],
})
export class ZoneListComponent implements OnInit {
    @Input() serial: string = '1';
    @Input() userId: number;
    @Input() allZone: boolean;
    zones: Zone[] = [];
    totalZones: any;
    allZonesId: string[] = [];
    isChecked: boolean = false;
    arrObj = [];
    arrObjRemove = [];
    checkedBox: boolean = false;
    isAllZones: boolean;

    constructor(private translate: TranslateService,private http: DataService, private toaster: ToastService, private router: Router,
                private activateRoute: ActivatedRoute) {
        this.activateRoute.params.subscribe(res => {
            if (res['id'] === 'new') {
                localStorage.setItem('is_all_zones', String(false));
            }
        });
    }

    ngOnInit() {
        this.isAllZones = (localStorage.getItem('is_all_zones') === 'true');
        if (this.userId) {
            this.getZones().then();
        }
    }
    translateText(key: string): string {
		let translation: string;
		this.translate.get(key).subscribe((res: string) => {
			translation = res;
		});
		return translation;
	}
    async getZones() {
        try {
            this.totalZones = (await this.http.query({__active__bool: true}, 'zone', 'auth')).total;
            const res = await this.http.query({__user_id__equal: this.userId, __limit: 1500, __page: 1, __include: 'zone'}, 'user_zone', 'auth');
            this.zones = res.data;
            this.totalZones = res.total;
            this.checkedBox = this.zones.length === this.totalZones;
        } catch (e) {
            this.zones = [];
            if (this.zones.length === 0) {
                localStorage.setItem('is_all_zones', String(false));
                this.isAllZones = (localStorage.getItem('is_all_zones') === 'true');
            }
        }
    }

    async addZone(event?: any) {
        try {
            if (this.isChecked && this.arrObj.length > 0) {
                await this.http.create(this.arrObj, {}, 'user_zone', 'auth');
                this.arrObj = [];
            } else {
                await this.http.create({
                    __action: 'add',
                    zone_id: event.id,
                    user_id: this.userId,
                }, {}, 'user_zone', 'auth');
            }
            this.zones.push(event);
            if (this.zones.length === this.totalZones) {
                this.checkedBox = true;
            }
            this.toaster.showToast('Successfully create zone.', 'Success', false);
        } catch (e) {
            this.toaster.showToast('Error creating zone due to duplicate entry', 'Error', true, e);
            this.arrObj = [];
            return e;
        }
    }

    async removeZone(id?: string, index?: number, value?: any) {
        try {
            if (this.isChecked === false && this.arrObjRemove.length > 0) {
                await this.http.create(this.arrObjRemove, {}, 'user_zone', 'auth');
                this.arrObjRemove = [];
            } else {
                await this.http.create({
                    __action: 'remove',
                    zone_id: id,
                    user_id: this.userId,
                }, {}, 'user_zone', 'auth');
            }

            this.zones.splice(index, 1);
            this.toaster.showToast('Successfully removed zone.', 'Success', false);
            if (this.zones.length < this.totalZones) {
                localStorage.setItem('is_all_zones', String(false));
                this.isAllZones = (localStorage.getItem('is_all_zones') === 'true');
                await this.getZones();
            }


        } catch (e) {
            this.toaster.showToast('Error removing zone ', 'Error', true, e);
        }
    }

    editZone(id?: string) {
        this.router.navigate(['/pages/management/base/zone/' + (id ? id : 'new')]);
    }

    async allZones(event: any) {
        localStorage.setItem('is_all_zones', event);
        this.isAllZones = (localStorage.getItem('is_all_zones') === 'true');
        this.isChecked = event;
        let query = {};
        try {
            if (event) {
                query = {'__action': 'add', 'user_id': this.userId};
            } else {
                query = {'__action': 'remove', 'user_id': this.userId};
            }
            const res = await this.http.createDirect(query, {}, 'user_link_with_all_zone', 'auth');
            if (res.success) {
                this.toaster.showToast(event ? 'Zones added successfully' : 'Zones removed successfully', event ? 'Added' : 'Removed', false);
                await this.getZones();
            }

        } catch (e) {
            this.toaster.showToast('Error add all zones', 'Error', true);
        }

        // const query = {};
        // query['__limit'] = 2500;
        // query['__only']= ['id', 'name'];
        // query['__active__bool']= true;
        // const data=(await this.http.query(query, 'zone', 'auth')).data;
        // if(event){
        // 	this.isChecked=event;
        // 	//this.checkedBox=true;
        // 	this.arrObj=[];
        // 	const found=data.some(({ id }) => this.zones.map(obj => obj.id).includes(id))
        // 	if(found){
        // 		this.zones.forEach(obj=>{
        // 			this.allZonesId.push(obj.id);
        // 			const obj1={}
        // 			obj1['__action']="add"
        // 			obj1['user_id']=this.userId
        // 			obj1['zone_id']=obj.id
        // 			this.arrObj.push(obj1);
        // 		})
        // 	}else{
        // 		data.forEach(obj=>{
        // 			this.allZonesId.push(obj.id);
        // 			const obj1={}
        // 			obj1['__action']="add"
        // 			obj1['user_id']=this.userId
        // 			obj1['zone_id']=obj.id
        // 			this.arrObj.push(obj1);
        // 		})
        // 	}
        // 	await this.addZone().then(r=>{
        // 		if(r?.error){
        // 			this.checkedBox=true;
        // 			this.cd.detectChanges();
        // 		}
        // 	})
        // 	this.getZones();
        // }else{
        // 	this.isChecked=event;
        // 	this.arrObjRemove=[];
        // 	this.zones.forEach(obj=>{
        // 		this.allZonesId.push(obj.id);
        // 		const obj1={}
        // 		obj1['__action']="remove"
        // 		obj1['user_id']=this.userId
        // 		obj1['zone_id']=obj.id
        // 		this.arrObjRemove.push(obj1);
        // 	})
        // 	await this.removeZone().then();
        // 	this.zones=[]
        // 	this.cd.detectChanges();
        // }
    }

    protected readonly localStorage = localStorage;
}
