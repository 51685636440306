import {Component, Input, OnInit} from '@angular/core';
import {DataService, ToastService} from '../../../@core/utils';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'ngx-branch-list',
	templateUrl: './branch-list.component.html',
	styleUrls: ['./branch-list.component.scss'],
})
export class BranchListComponent implements OnInit {
	@Input()
	serial: string = '1';
	@Input()
	branches: any[] = [];
	@Input()
	userId: number;

	constructor(private http: DataService, private toaster: ToastService, private router: Router,
				private translate: TranslateService) {
	}
	translateText(key: string): string {
		let translation: string;
		this.translate.get(key).subscribe((res: string) => {
			translation = res;
		});
		return translation;
	}

	ngOnInit() {
	}

	async addBranch(event) {
		try {
			await this.http.create({
				__action: 'add',
				branch_id: event.id,
				user_id: this.userId,
			}, {}, 'user_branch_association');
			this.branches.push(event);
			this.toaster.showToast(this.translateText('Successfully create branch.'), 'Success', false);
		} catch (e) {
			this.toaster.showToast(this.translateText('Error removing branch '), 'Error', true, e);
		}
	}

	async removeBranch(id: number, index: number) {
		try {
			await this.http.create({
				__action: 'remove',
				branch_id: id,
				user_id: this.userId,
			}, {}, 'user_branch_association');
			this.branches.splice(index, 1);
			this.toaster.showToast(this.translateText('Successfully removed branch.'), 'Success', false);
		} catch (e) {
			this.toaster.showToast(this.translateText('Error removing branch '), 'Error', true, e);
		}
	}

	editBranch(id?: number) {
		this.router.navigate(['/pages/management/base/branch/' + (id ? id.toString(10) : 'new')]);
	}


}
