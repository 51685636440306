import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import * as L from 'leaflet';
import {icon, Marker, marker} from 'leaflet';
import {Address, Category, Outlet, Zone} from '../../../@core/models';
import 'leaflet-draw';
import 'leaflet-easybutton';
import {NbDialogService} from '@nebular/theme';
import {DataService, ToastService} from '../../../@core/utils';
import {environment} from '../../../../environments/environment';
// noinspection ES6PreferShortImport
import {DialogComponent} from '../dialog/dialog.component';
import circleToPolygon from 'circle-to-polygon';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'ngx-map-view',
    templateUrl: './map-view.component.html',
    styleUrls: ['./map-view.component.scss'],
})
export class MapViewComponent implements AfterViewInit, OnChanges {

    @Input() zone: Zone = {} as Zone;
    @Input() outlets: Outlet[] = [];
    @Input() serial: string;
    @Input() category: Category;
    @Input() minHeight: string;
    @Input() geom: any;
    @Input() address: Address;
    @Output() reload: EventEmitter<any> = new EventEmitter<any>();
    showSearch: boolean = false;
    useGoogleMaps: any = false;

    map: L.Map;
    // options = {
    //     layers: [
    //         false
    //           ? (L.gridLayer as any).googleMutant({ type: 'roadmap' }) // Google Maps Roadmap
    //           : L.tileLayer('https://1.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?apiKey=' + environment.hereApiKey, { // Here Maps
    //               attribution: '',
    //             }),
    //       ],
    //     // measureControl: true,
    //     minZoom: 5,
    //     maxZoom: 18,
    //     maxNativeZoom: 20,
    //     fullscreenControl: true,
    //     zoomControl: true,
    //     zoom: 12,
    //     center: L.latLng(28.7163, 77.1563),
    // };

    options:any;
    drawItems = new L.FeatureGroup();


    drawOptions: any = {
        position: 'bottomright',
        draw: {
            polyline: false,
            rectangle: false,
            polygon: true,
            marker: false,
            circle: true,
            circlemarker: false,
        },
        edit: {
            featureGroup: this.drawItems,
        },
    };
    useGoogleMap: any = false;

    showAddOnClearAllBool: boolean = false;
    drawOptionsWithEdit = {
        position: 'bottomright',
        draw: {
            polyline: false,
            rectangle: false,
            polygon: true,
            marker: false,
            circle: true,
            circlemarker: false,
        },
        edit: {
            featureGroup: this.drawItems,
        },
    };
    private circleData: any;
    allZone: any;

    constructor(private translate: TranslateService
,        private dialogService: NbDialogService, private http: DataService,
                private cd: ChangeDetectorRef, private toaster: ToastService, private router: Router) {
        this.useGoogleMaps = localStorage.getItem(JSON.stringify('google_map_config'));
        console.log(this.useGoogleMaps);
        
    }


    ngOnChanges(changes: SimpleChanges): void {
        this.initializeMapOptions();
        if (changes.hasOwnProperty('zone') || changes.hasOwnProperty('category')) {
            this.drawOptions = {
                position: 'bottomright',
                draw: {
                    polyline: false,
                    rectangle: false,
                    polygon: true,
                    marker: false,
                    circle: true,
                    circlemarker: false,
                },
                edit: {
                    featureGroup: this.drawItems,
                },
            };
        }
    }

    initializeMapOptions(): void {
		this.useGoogleMap = JSON.parse(localStorage.getItem('google_map_config'));
		this.options = {
		  layers: [
			this.useGoogleMap
			  ? (L.gridLayer as any).googleMutant({ type: 'roadmap' }) // Google Maps Roadmap
			  : L.tileLayer(
				  'https://1.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?apiKey=' + environment.hereApiKey,
				  {
					attribution: '',
					maxZoom: 18,
					minZoom: 2,
				  }
				), // Here Maps
		  ],
            minZoom: 5,
            maxZoom: 18,
            maxNativeZoom: 20,
            fullscreenControl: true,
            zoomControl: true,
            zoom: 12,
            center: L.latLng(28.7163, 77.1563),
		};
	  }

    translateText(key: string): string {
		let translation: string;
		this.translate.get(key).subscribe((res: string) => {
			translation = res;
		});
		return translation;
	}
    ngAfterViewInit(): void {
        setTimeout(() => {
            this.map.invalidateSize(true);
            this.addLayer();
        }, 500);
        if (this.address.id) {
            this.getAddress().then();
        }
    }

    addLayer() {
        if (this.zone && 'geom' in this.zone) {
            const self = this;
            const fenceId: any = this.zone.id;
            const layer = L.geoJSON(this.zone.geom, {
                onEachFeature: function (feature: any, l) {
                    const props: any = feature.properties = feature.properties || {}; // Initialize feature.properties
                    props['fenceId'.toString()] = fenceId;
                    self.drawItems.addLayer(l);
                },
            });
            this.map.addLayer(layer);
            if (this.zone.geom) {
                const bounds = L.geoJSON(this.zone.geom).getBounds();
                this.map.fitBounds(bounds);
            }

            this.drawItems = layer;
        } else if (this.category && 'geom' in this.category) {
            const self = this;
            const fenceId: any = this.category.id;
            const layer = L.geoJSON(this.category.geom, {
                onEachFeature: (feature: any, l) => {
                    const props: any = feature.properties = feature.properties || {}; // Initialize feature.properties
                    props['fenceId'.toString()] = fenceId;
                    self.drawItems.addLayer(l);
                },
            });
            this.map.addLayer(layer);
            if (this.category.geom) {
                const bounds = L.geoJSON(this.category.geom).getBounds();
                this.map.fitBounds(bounds);
            }

            this.drawItems = layer;
        }
        if (this.outlets && this.outlets.length) {
            const markers: Marker[] = [];
            this.outlets.filter(o => o.address).forEach(outlet => {
                const m = marker([outlet.address.geom.latitude, outlet.address.geom.longitude], {
                    icon: icon({
                        iconSize: [25, 25],
                        iconAnchor: [20, 0],
                        iconUrl: 'assets/images/merchant.png',
                    }),
                }).bindTooltip(outlet.name, {
                    permanent: true,
                    direction: 'top',
                    className: 'tooltipStyle',
                }).openTooltip();
                m.addTo(this.map);
                markers.push(m);
                this.map.fitBounds(markers.map(i => [i.getLatLng().lat, i.getLatLng().lng]));
            });
        }
    }

    onMapReady(map: L.Map) {
        this.map = map;

        L.easyButton(` <i class=""> <i class="ion-ios-search"></i> </i> `, () => {
            this.showSearch = !this.showSearch;
            this.cd.detectChanges();
        }).addTo(this.map);

        L.easyButton(` <i class="">
        <i class="ion-eye"></i>
      </i>
      `, async () => {
            this.allZone = await this.http.get('', {__active__bool: 'true', __only: ['name', 'geom', 'active']}, 'zone', 'auth');
            this.allZone = this.allZone.data;
            this.allZone.forEach(ele => {
                if ('geom' in ele) {
                    const self = this;
                    const fenceId: any = ele.id;
                    const layer = L.geoJSON(ele.geom, {
                        onEachFeature: function (feature: any, l) {
                            const props: any = feature.properties = feature.properties || {}; // Initialize feature.properties
                            props['fenceId'.toString()] = fenceId;
                            self.drawItems.addLayer(l);
                        },
                        style: function (feature) {
                            return {
                                color: 'red',
                            };
                        },
                    });
                    this.map.addLayer(layer);
                    if (ele.geom) {
                        const bounds = L.geoJSON(ele.geom).getBounds();
                        this.map.fitBounds(bounds);
                    }

                    this.drawItems = layer;
                }
            });
            this.map.setZoom(5);
        }).addTo(map);



        const self = this;
        this.map.addLayer(this.drawItems);
        this.map.on(L.Draw.Event.EDITED, (e: any) => {
            const layers = e.layers;
            layers.eachLayer(async function (layer) {
                const featureGroup = L.featureGroup();
                featureGroup.addLayer(layer);
                const data: any = featureGroup.toGeoJSON();

                try {
                    await self.http.update(layer.feature.geometry.properties.fenceId
                        , {geom: data.features[0].geometry}, {}, 'zone', 'auth');
                } catch (error) {
                }
            });

        });
        this.map.on(L.Draw.Event.DELETED, () => {
            let enableEdit = true;
            this.drawItems.eachLayer((layer) => {
                if (this.map.hasLayer(layer)) {
                    enableEdit = false;
                }
            });
            if (enableEdit) {
                this.showAddOnClearAllBool = true;
                this.cd.detectChanges();
            }

        });


        this.map.on(L.Draw.Event.CREATED, (e: any) => {
            const layer = e.layer;
            const type = e.layerType;

            if (type === 'circle') {

                const theCenterPt = layer.getLatLng();

                const center: any = [theCenterPt.lng, theCenterPt.lat];

                const theRadius = layer.getRadius();

                this.drawItems.addLayer(layer);

                this.circleData = circleToPolygon(center, theRadius);

            }
        });
    }

    async createdZone(e) {

        if (this.zone.id) {
            const featureGroup = L.featureGroup();
            featureGroup.addLayer(e.layer);
            const featureData: any = featureGroup.toGeoJSON();

            let data;
            if (featureData.features[0].geometry.type === 'Point') {
                data = this.circleData;
            } else {
                data = null;
                const rectData: any = featureGroup.toGeoJSON();
                data = rectData.features[0].geometry;
            }
            this.dialogService.open(DialogComponent, {
                context: {
                    title: 'Create GeoFence',
                    body: this.zone.name,
                    type: 'confirm',
                },
            }).onClose.subscribe(async res => {
                if (res) {
                    await this.http.update(this.zone.id, {geom: data}, {}, 'zone', 'auth');
                    this.zone.geom = data.features ? data.features[0].geometry : this.circleData;
                    this.reloadComponent();
                    this.showAddOnClearAllBool = false;
                    this.cd.detectChanges();
                    this.toaster.showToast(this.translateText('Successfully added zone.'), 'Success', false);
                }
            });
        } else if (this.category.id) {
            const featureGroup = L.featureGroup();
            featureGroup.addLayer(e.layer);
            const featureData: any = featureGroup.toGeoJSON();
            let data;
            if (featureData.features[0].geometry.type === 'Point') {
                data = this.circleData;
            } else {
                data = null;
                const rectData: any = featureGroup.toGeoJSON();
                data = rectData.features[0].geometry;
            }
            this.dialogService.open(DialogComponent, {
                context: {
                    title: 'Create GeoFence',
                    body: this.category.name,
                    type: 'confirm',
                },
            }).onClose.subscribe(async res => {
                if (res) {
                    await this.http.update(this.category.id, {geom: data}, {}, 'category', 'auth');
                    // this.category.geom = data.features[0].geometry
                    this.category.geom = data.features ? data.features[0].geometry : this.circleData;
                    this.reloadComponent();
                    this.showAddOnClearAllBool = false;
                    this.cd.detectChanges();
                    this.toaster.showToast(this.translateText('Successfully added zone.'), 'Success', false);
                }
            });
        }

    }

    search(event) {
        const lat = event.geom ? event.geom.latitude : event.lat.trim();
        const lng = event.geom ? event.geom.longitude : event.lng.trim();
        const _marker = marker([lat, lng], {
            icon: icon({
                iconUrl: 'assets/images/marker-icon.png',
            }),
        });

        this.map.addLayer(_marker);

        this.map.setView([lat, lng], 14);
    }

    reloadComponent() {
        const currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]).then();
        this.reload.emit('reload');
    }

    async getAddress() {
        const myIcon = L.icon({
            iconSize: [25, 41],
            iconAnchor: [13, 41],
            iconUrl: 'assets/images/marker-icon.png',
            // shadowUrl: 'assets/images/marker-shadow.png',
        });
        try {
            const addressMarker = new L.Marker([this.address.geom.latitude, this.address.geom.longitude], {
                icon: myIcon,
            });
            addressMarker.addTo(this.map);
            this.map.panTo([this.address.geom.latitude, this.address.geom.longitude]);
        } catch (e) {
            console.error(e);
        }
    }
}
