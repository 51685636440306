import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'ngx-rider-info-dialog',
	templateUrl: './rider-info-dialog.component.html',
	styleUrls: ['./rider-info-dialog.component.scss'],
})
export class RiderInfoDialogComponent implements OnInit {

	columns: any[] = [];
	customData: any;
	data: any[] = [];
	displayFn?: (row: any, column: any) => {};

	constructor(private translate: TranslateService) {
		this.columns =
			[{
				name: 'name',
				displayName: this.translateText('Rider'),
			},
				{
					name: 'mobile_number',
					displayName: this.translateText('Mobile No.'),
				},
				{
					name: 'email',
					displayName: this.translateText('Email'),
				},
				{
					name: 'Last Login',
					displayName: this.translateText('Last Login'),
					displayFn: function (row, c) {
						if (row.auth.last_login_at) {
							return new Date(row.auth.last_login_at).toString().slice(3, 25);
						} else {
							return '';
						}
					},
				},
				{
					name: 'app_version',
					displayName: this.translateText('App version'),
					displayFn: (r => r.device_details ? r.device_details.app_version : ''),
				},
				{
					name: 'is_active',
					displayName: this.translateText('Active'),
				},
				{
					name: 'manufacturer',
					displayName: this.translateText('Manufacturer'),
					displayFn: function (row, c) {
						if (row.device_details) {
							return row.device_details.manufacturer;
						} else {
							return '';
						}
					},
				},
				{
					name: 'model',
					displayName: this.translateText('Model'),
					displayFn: function (row, c) {
						if (row.device_details) {
							return row.device_details.model;
						} else {
							return '';
						}
					},
				},
				{
					name: 'sdk',
					displayName: this.translateText('SDK'),
					displayFn: function (row, c) {
						if (row.device_details) {
							return row.device_details.sdk;
						} else {
							return '';
						}
					},
				},
				{
					name: 'androidVersion',
					displayName: this.translateText('Android Version'),
					displayFn: function (row, c) {
						if (row.device_details) {
							return row.device_details.androidVersion;
						} else {
							return '';
						}
					},
				},

			];
	}
	translateText(key: string): string {
		let translation: string;
		this.translate.get(key).subscribe((res: string) => {
			translation = res;
		});
		return translation;
	}
	ngOnInit() {
		this.data.push(this.customData);
	}

}
