import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Auth} from '../../../@core/models';
import {DataService, ToastService} from '../../../@core/utils';
import {Location} from '@angular/common';
import {PagesService} from '../../../pages/pages.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: "ngx-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.scss"],
})
export class AuthComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  id: string = null;
  @Input()
  temppassword: string = null;
  auth: Auth = <Auth>{};
  password: string = undefined;
  roles$: Promise<any>;
  loading: boolean = false;
  @Output()
  authId: EventEmitter<string> = new EventEmitter();
  oldData: Auth;

  constructor(
    private translate: TranslateService,
    private http: DataService,
    private toaster: ToastService,
    private _location: Location,
    private pagesService: PagesService
  ) {}

  ngOnInit() {}
  translateText(key: string): string {
    let translation: string;
    this.translate.get(key).subscribe((res: string) => {
      translation = res;
    });
    return translation;
  }
  ngAfterViewInit(): void {
    if (this.id) {
      this.getAuth().then();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty("id") && changes.id.currentValue) {
      this.getAuth();
	}
	if (
    changes.hasOwnProperty("temppassword") &&
    changes.temppassword.currentValue
	) {
		this.password = changes.temppassword.currentValue;
	this.auth.password = changes.temppassword.currentValue;
  }  
  }

  async getAuth() {
    try {
      this.auth = await this.http.get(
        this.id,
        { __exclude: ["user", "rider", "merchant"] },
        "auth",
        "auth"
      );
      this.oldData = Object.assign({}, this.auth);
    } catch (e) {}
  }

  async save() {
    this.loading = true;
    if (this.password) {
      this.auth.password = this.password;
    }
    try {
      if (this.id) {
        const newAuthData = this.pagesService.Compare(this.oldData, this.auth);
        if (Object.keys(newAuthData).length) {
          await this.http.update(this.id, newAuthData, {}, "auth", "auth");
        }
      } else {
        const res = await this.http.create(
          this.auth,
          { __only: "id" },
          "auth",
          "auth"
        );
        this.id = res[0].id;
        this.auth.id = res[0].id;
        this.authId.emit(this.auth.id);
      }
      this.toaster.showToast(
        this.translateText("Saved auth successful"),
        "Success",
        false
      );
    } catch (e) {
      this.toaster.showToast(
        this.translateText("Error saving auth"),
        "Error",
        true,
        e
      );
    }
    this.loading = false;
  }

  async cancel() {
    this._location.back();
  }
}
