import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
    NbActionsModule,
    NbAlertModule,
    NbBadgeModule,
    NbButtonModule,
    NbCalendarRangeModule,
    NbCardModule,
    NbCheckboxModule,
    NbContextMenuModule,
    NbDatepickerModule,
    NbIconModule,
    NbInputModule,
    NbLayoutModule,
    NbListModule,
    NbMenuModule,
    NbPopoverModule, NbRadioModule,
    NbRouteTabsetModule,
    NbSearchModule,
    NbSelectModule,
    NbSidebarModule,
    NbSpinnerModule,
    NbTabsetModule,
    NbThemeModule,
    NbTimepickerModule, NbToggleModule,
    NbTooltipModule,
    NbUserModule,
} from '@nebular/theme';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {NbSecurityModule} from '@nebular/security';
// @ts-ignore
import {
    AddressComponent,
    BranchListComponent,
    CategoryListComponent,
    CategoryRateListComponent,
    CategorySelectionComponent,
    DialogComponent,
    FileUploadComponent,
    FooterComponent,
    HeaderComponent,
    LayoutDirectionSwitcherComponent,
    MapViewComponent,
    MerchantListComponent,
    OutletListComponent,
    SearchInputComponent,
    SwitcherComponent,
    UserListComponent,
} from './components';
import {CapitalizePipe, NumberWithCommasPipe, PluralPipe, RoundPipe, TimingPipe} from './pipes';
import {OneColumnLayoutComponent, ThreeColumnsLayoutComponent, TwoColumnsLayoutComponent} from './layouts';
import {DEFAULT_THEME} from './styles/theme.default';
import {COSMIC_THEME} from './styles/theme.cosmic';
import {CORPORATE_THEME} from './styles/theme.corporate';
import {DARK_THEME} from './styles/theme.dark';
import {RouterModule} from '@angular/router';
import {DataTableComponent} from './components/data-table/data-table.component';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {FormsModule} from '@angular/forms';
import {NgbDropdownModule, NgbModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {TypeAheadComponent} from './components/type-ahead/type-ahead.component';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {LeafletDrawModule} from '@asymmetrik/ngx-leaflet-draw';
import {GoogleSearchComponent} from './components/google-search/google-search.component';
import {FilterPipe} from './pipes/filter.pipe';
import {RiderDropdownComponent} from './components/rider-dropdown/rider-dropdown.component';
import {
    CustomerInfoDialogComponent,
} from '../pages/management/base/customers/customer-info-dialog/customer-info-dialog.component';
import {RiderInfoDialogComponent} from '../pages/management/base/rider/rider-info-dialog/rider-info-dialog.component';
import {RiderListComponent} from './components/rider-list/rider-list.component';
import {AuthComponent} from './components/auth/auth.component';
import {ZoneListComponent} from './components/zone-list/zone-list.component';
import {PermissionComponent} from './components/permission/permission.component';
import {CopyClipboardDirective} from './directives/copy-clipboard.directive';
import {NgSelectModule} from '@ng-select/ng-select';
import {
    PartnerCategoryRateListComponent,
} from './components/partner-category-rate-list/partner-category-rate-list.component';
import {PartnerZoneListComponent} from './components/partner-zone-list/partner-zone-list.component';
import {VehicleZoneListComponent} from './components/vehicle-zone-list/vehicle-zone-list.component';
import {CardComponent} from './components/card/card.component';
import {SortByPipe} from './pipes/sort-by.pipe';
import {DeviceDetailsComponent} from './components/device-details/device-details.component';
import {DateTimeRangePickerComponent} from './components/date-time-range-picker/date-time-range-picker.component';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from '@danielmoncada/angular-datetime-picker';
import {ApproveCardComponent} from './components/approve-card/approve-card.component';
import {
    CeleryTaskStatusDialogComponent,
} from './components/celery-task-status-dialog/celery-task-status-dialog.component';
import {VehicleListComponent} from './components/vehicle-list/vehicle-list.component';
import {OnlineStatusComponent} from './components/online-status/online-status.component';
import {MbscDatepickerModule} from '@mobiscroll/angular';
import {PaginationComponent} from './components/pagination/pagination.component';
import {DateFilterComponent} from './components/date-filter/date-filter.component';
import {NewPaginationComponent} from './components/new-pagination/new-pagination.component';
import {LiveTrackingComponent} from './components/live-tracking/live-tracking.component';
import { BulkUploadComponent } from './components/bulk-upload/bulk-upload.component';
import { PrimeDataTableComponent } from './components/prime-data-table/prime-data-table.component';
import {TableModule} from 'primeng/table';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import {TooltipModule} from 'primeng/tooltip';
import {TranslateModule} from '@ngx-translate/core';

const NB_MODULES = [
    NbLayoutModule,
    NbMenuModule,
    NbUserModule,
    NbActionsModule,
    NbSearchModule,
    NbSidebarModule,
    NbContextMenuModule,
    NbSecurityModule,
    NbButtonModule,
    NbSelectModule,
    NbIconModule,
    NbEvaIconsModule,
    NbTabsetModule,
    NbRouteTabsetModule,
    NbListModule,
    NbCheckboxModule,
    NbCardModule,
    NbInputModule,
    NbSelectModule,
    NbDatepickerModule,
    NbCalendarRangeModule,
    NbSpinnerModule,
    NbPopoverModule,
];

const NG_MODULES = [
    RouterModule,
    Ng2SmartTableModule,
    FormsModule,
    LeafletModule,
    LeafletDrawModule,
];

const NGB_MODULES = [
    NgbModule,
    NgbDropdownModule,
    NgbTypeaheadModule,
];

const COMPONENTS = [
    SwitcherComponent,
    LayoutDirectionSwitcherComponent,
    HeaderComponent,
    FooterComponent,
    SearchInputComponent,
    OneColumnLayoutComponent,
    ThreeColumnsLayoutComponent,
    TwoColumnsLayoutComponent,
    DataTableComponent,
    TypeAheadComponent,
    UserListComponent,
    OutletListComponent,
    MapViewComponent,
    BranchListComponent,
    DialogComponent,
    CategorySelectionComponent,
    MerchantListComponent,
    AddressComponent,
    FileUploadComponent,
    GoogleSearchComponent,
    RiderDropdownComponent,
    CategoryListComponent,
    CategoryRateListComponent,
    CustomerInfoDialogComponent,
    PartnerZoneListComponent,
    VehicleZoneListComponent,
    RiderInfoDialogComponent,
    PartnerCategoryRateListComponent,
    CardComponent,
];
const PIPES = [
    CapitalizePipe,
    PluralPipe,
    RoundPipe,
    TimingPipe,
    NumberWithCommasPipe,
    FilterPipe,
    CopyClipboardDirective,
];

@NgModule({
    imports: [CommonModule, ...NB_MODULES, ...NG_MODULES, ...NGB_MODULES, NgSelectModule, DragDropModule,
        // tslint:disable-next-line:max-line-length
        OwlDateTimeModule, OwlNativeDateTimeModule, NbTooltipModule, NbBadgeModule, NbTimepickerModule, NbAlertModule, MbscDatepickerModule, NbToggleModule, TableModule, InputTextModule, ButtonModule, RippleModule, TooltipModule, TranslateModule, NbRadioModule],
    exports: [CommonModule, ...PIPES, ...COMPONENTS, RiderListComponent, AuthComponent, ZoneListComponent,
        // tslint:disable-next-line:max-line-length
        PermissionComponent, SortByPipe, DeviceDetailsComponent, DateTimeRangePickerComponent, ApproveCardComponent,
        OnlineStatusComponent, VehicleListComponent, PaginationComponent, DateFilterComponent, NewPaginationComponent,
        LiveTrackingComponent, PrimeDataTableComponent],
    declarations: [...COMPONENTS, ...PIPES, CategoryRateListComponent, RiderListComponent, AuthComponent,
        ZoneListComponent, PermissionComponent, CopyClipboardDirective, PartnerCategoryRateListComponent, OnlineStatusComponent,
        PartnerZoneListComponent, VehicleZoneListComponent, SortByPipe, DeviceDetailsComponent,
        DateTimeRangePickerComponent, ApproveCardComponent, CeleryTaskStatusDialogComponent, VehicleListComponent, PaginationComponent,
        DateFilterComponent,
        NewPaginationComponent,
        LiveTrackingComponent,
        BulkUploadComponent,
        PrimeDataTableComponent,
        ],
    entryComponents: [DialogComponent, CustomerInfoDialogComponent, RiderInfoDialogComponent, CeleryTaskStatusDialogComponent],
})
export class ThemeModule {
    static forRoot(): ModuleWithProviders<ThemeModule> {
        return {
            ngModule: ThemeModule,
            providers: [
                ...NbThemeModule.forRoot(
                    {
                        name: 'corporate',
                    },
                    [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME],
                ).providers,
            ],
        };
    }
}