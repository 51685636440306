import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {DataService, ToastService} from '../../../@core/utils';
import {Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'ngx-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.css'],
})
export class VehicleListComponent {

  @Input() serial: string = '1';
  @Input() zoneId: string;
  @Input() catId: any;

  @Input() enable:boolean

  vehicles: any[] = [];
  filters: any;
  infoPopup = this.translateText('If unable to Add Vehicle, Create Zone First (Step 1)');

  constructor(private http: DataService, private toaster: ToastService, private router: Router, private cd: ChangeDetectorRef,
              private translate: TranslateService) {
  }

    translateText(key: string): string {
        let translation: string;
        this.translate.get(key).subscribe((res: string) => {
            translation = res;
        });
        return translation;
    }
  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit(): void {
      console.log(this.vehicles);
      if (this.zoneId) {
      this.filters = {__only: ['id', 'registration_number'],__zones_id__ne: this.zoneId};
    } else if (this.catId) {
      //this.filters = {__category_id__equal: this.catId};
          this.filters = {__only: ['id', 'registration_number'],__category_id__ne: this.catId};
    }
    if (this.zoneId ||this.catId ) {
      this.getVehicles().then();
    }
    this.cd.detectChanges();
  }


  async getVehicles() {
    let query: any = {};
    if (this.zoneId) {
      query = {__zone_id__equal: this.zoneId};
    } else if (this.catId) {
      query = {__category_id__equal: this.catId};
    }
    try {
        let vehicles: any;
        if(this.zoneId){
            vehicles = await this.http.query(query, 'vehicle_zone', 'auth');

        }else{
            vehicles = await this.http.query(query, 'vehicle', 'auth');
        }
        if (vehicles.data && vehicles.data.length) {
            this.vehicles = vehicles.data;
        } else {
            this.vehicles = [];
        }
    } catch (e) {
    }
  }


  editVehicle(id?: number) {
    this.router.navigate(['/pages/management/base/vehicle-detail/' + (id ? id : 'new')]);
  }



  async addVehicle(event) {
    if (!this.catId && this.zoneId) {
      		try {
      			await this.http.create( {
      				zone_id: this.zoneId,
                    vehicle_id: event.id,
                    __action: 'add'
      			}, {}, 'vehicle_zone', 'auth');
      			// this.vehicles.push(event);
                  await this.getVehicles()
      			this.toaster.showToast(this.translateText('Successfully added user.'), 'Success', false);
      		} catch (e) {
      			this.toaster.showToast(this.translateText('Error adding user '), 'Error', true, e);
      		}
      	} else if (!this.zoneId && this.catId) {
      		try {
      			await this.http.update(event.id, {category_id: this.catId}, {}, 'vehicle', 'auth');
      			this.vehicles.push(event);
                // await this.http.create( {
                //     category_id: this.catId,
                //     vehicle_id: event.id,
                //     __action: 'add'
                // }, {}, 'vehicle_zone', 'auth');
                // this.vehicles.push(event);
                // await this.getVehicles()
      			this.toaster.showToast(this.translateText('Successfully added user.'), 'Success', false);
      		} catch (e) {
      			this.toaster.showToast(this.translateText('Error adding user '), 'Error', true, e);
      		}
      	} else {
      		this.toaster.showToast(this.translateText('Error adding user: Please fill details first. '), 'Error', true);
      	}
  }
  async removeUser(id: number, index: number) {
    if (this.zoneId) {
      try {
          await this.http.create( {
              zone_id: this.zoneId,
              vehicle_id: id,
              __action: 'remove',
          }, {}, 'vehicle_zone', 'auth');
        this.vehicles.splice(index, 1);
        this.toaster.showToast(this.translateText('Successfully removed vehicle.'), 'Success', false);
      } catch (e) {
        this.toaster.showToast(this.translateText('Error removing vehicle '), 'Error', true, e);
      }
    } else if (!this.zoneId) {
      try {
        // await this.http.update(id, {vehicle_id: null}, {}, 'vehicle', 'auth');
          await this.http.update(id, {
              category_id: null,
          }, {}, 'vehicle', 'auth');
        this.vehicles.splice(index, 1);
        this.toaster.showToast(this.translateText('Successfully removed vehicle.'), 'Success', false);
      } catch (e) {
        this.toaster.showToast(this.translateText('Error removing vehicle '), 'Error', true, e);
      }
    }else {
      this.toaster.showToast(this.translateText('Error removing user: Please fill details first. '), 'Error', true);
    }
  }




}
