import {Component} from '@angular/core';
import {NbAuthComponent} from '@nebular/auth';


@Component({
	selector: 'ngx-auth',
	styleUrls: ['./auth.component.scss'],
	template: `
  <nb-layout>
  <nb-layout-column style="padding: 0px !important">
  <router-outlet></router-outlet>
  </nb-layout-column>
  </nb-layout>
  `,
})
export class AuthComponent extends NbAuthComponent {
}
