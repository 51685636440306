import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AuthGuard} from './@core/utils';
import {ShareTripComponent} from './pages/share-trip/share-trip.component';

const routes: Routes = [
	{
		path: 'pages', canActivate: [AuthGuard],
		loadChildren: () => import('../app/pages/pages.module')
			.then(m => m.PagesModule),
	},
	{
		path: 'trip/share',
		component: ShareTripComponent,
	},
	{
		path: 'auth',
		loadChildren: () => import('./auth/auth.module')
			.then(m => m.AuthModule),
	},

	{path: '', redirectTo: 'pages', pathMatch: 'full'},
	{path: '**', redirectTo: 'pages'},
];

const config: ExtraOptions = {
    useHash: false,
    relativeLinkResolution: 'legacy',
};

@NgModule({
	imports: [RouterModule.forRoot(routes, config)],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
