import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DateTimeRangePickerService {
customDateRangePicker = new BehaviorSubject([])
  showPath = new BehaviorSubject(false)
  constructor() { }
}
