import {Component} from '@angular/core';
import {environment} from "../../../../environments/environment";

@Component({
	selector: 'ngx-footer',
	styleUrls: ['./footer.component.scss'],
	template: `
    <span class="created-by font12 color-grey">Developed by Roadcast</span>
    <div class="socials">
		<span class="font12 color-grey versionf">v{{currentApplicationVersion}}</span>
<!--      <a href="#" target="_blank" class="ion ion-social-github"></a>-->
<!--      <a href="#" target="_blank" class="ion ion-social-facebook"></a>-->
<!--      <a href="#" target="_blank" class="ion ion-social-twitter"></a>-->
<!--      <a href="#" target="_blank" class="ion ion-social-linkedin"></a>-->
    </div>
  `,
})
export class FooterComponent {

	currentApplicationVersion = environment.appVersion;
}
