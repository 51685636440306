import {Injectable, OnDestroy} from '@angular/core';
import {Subject, interval, Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {DataService} from './data.service';
import {Task} from '../models/task';

@Injectable({
  providedIn: 'root',
})
export class GenerateInvoiceService implements OnDestroy {
  invoiceStatus$: Subject<Task> = new Subject() as Subject<Task>;
  currentStatus: string;
  sub: Subscription;


  constructor(private http: DataService) { }

  async getTaskStatus(task_id): Promise<Task> {
    return await this.http.query({'task_id': task_id}, 'invoice_status', 'auth');
  }

  async init(task_id?: string) {
    const taskStatusData = await this.getTaskStatus(task_id);
    this.currentStatus = taskStatusData.state;
    this.invoiceStatus$.next(taskStatusData);
    this.sub = interval(2000)
        .subscribe(() => {
            this.getTaskStatus(task_id).then(res => {
              this.invoiceStatus$.next(res);
            });
        });
  }

  ngOnDestroy(): void {
    if (this.sub) {
      console.error('destroyed');
      this.sub.unsubscribe();
    }
  }

  destroy() {
    this.ngOnDestroy();
  }
}
