import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NbDialogRef, NbDialogService } from "@nebular/theme";
import { LocalDataSource, Ng2SmartTableComponent } from "ng2-smart-table";
import * as XLSX from "xlsx";
import * as moment from "moment/moment";
import { DataService, ToastService } from "../../../@core/utils";
import { CeleryTaskStatusDialogComponent } from "../celery-task-status-dialog/celery-task-status-dialog.component";

@Component({
  selector: "ngx-bulk-upload",
  templateUrl: "./bulk-upload.component.html",
  styleUrls: ["./bulk-upload.component.css"],
})
export class BulkUploadComponent implements OnInit {
  taskID: any;
  upload: boolean = false;
  validate: boolean = false;
  loading: boolean = false;
  response: any[];
  constructor(
    public ref: NbDialogRef<BulkUploadComponent>,
    protected http: DataService,
    private toaster: ToastService,
    private nbDialogService: NbDialogService
  ) {}
  manualRouteTableSource: any = new LocalDataSource();
  manualRouteTableSettings: any = {};
  type: any;
  obj :any
  categoryName: any;
  calender_id:any;
  totalCount = 0;
  days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  @ViewChild("table") table: Ng2SmartTableComponent;
  @HostListener("click", ["$event.target"]) onClick(e) {
  }
  ngOnInit(): void {}
  ngAfterViewInit() {}

  onDelete(event) {
    if (window.confirm("Are you sure you want to delete?")) {
      event.confirm.resolve();
    } else {
      event.confirm.reject();
    }
  }

  onDeleteWhenExternalMode(event) {
    if (window.confirm("Are you sure you want to delete?")) {
      this.manualRouteTableSource.remove(event.data);
    } else {
      // event.confirm.reject();
    }
  }

  async manualRouteFileUpload(e) {
    this.manualRouteTableSource.reset();
    this.loading = true;
    const file: File = e.files[0];
    const reader = new FileReader();
    reader.onload = async () => {
      const data = reader.result;
      let TmsData = [];
      const workbook = XLSX.read(data, { type: "binary", cellDates: true });
      const tmsData: any[] = XLSX.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        {
          raw: false,
          header: 0,
          defval: null,
        }
      );
      if(TmsData){
        this.loading=false
      }
      if(this.type==="roster"){
        const arr=tmsData
        this.rosterBulk(arr)
      }
      if (this.type === "rider") {
        tmsData.forEach((tms) => {
          tms.dob = tms.dob ? moment(tms.dob).format("YYYY-MM-DD") : null;

          tms.dl_licence_expiration = tms.dl_licence_expiration
            ? moment(tms.dl_licence_expiration).format("YYYY-MM-DD")
            : null;
          tms.passport_expiry = tms.passport_expiry
            ? moment(tms.passport_expiry).format("YYYY-MM-DD")
            : null;
          tms.resident_id_expiration = tms.resident_id_expiration
            ? moment(tms.resident_id_expiration).format("YYYY-MM-DD")
            : null;
            TmsData.push(tms);
        });
      }
      if (this.type === "holiday_list") {
        tmsData.forEach((tms) => {
          let data = {
            holiday_name: "",
            holiday_date: "",
            is_restricted: false,
            is_active: true,
            calender_year_id:this.calender_id
          };
          data.holiday_name = tms.holiday_name ? tms.holiday_name : null;
          data.holiday_date = tms.holiday_date
            ? moment(tms.holiday_date).format("YYYY-MM-DD")
            : null;
          tms["is_restricted"] = false;
          tms["is_active"] = true;
          tms["is_active"] = true;
          tms["calender_year_id"]=this.calender_id;
          TmsData.push(data);
        });
      }

      if (this.type === "restroom_category") {
        tmsData.forEach((tms) => {
          let data = { type: "", icon: "", name: "", active: true };
          data.type = tms.type ? tms.type : null;
          data.icon = tms.icon ? tms.icon : null;
          data.name = tms.name ? tms.name : null;
          data.active = tms.active
            ? tms.active.toLowerCase() === "true"
              ? true
              : false
            : false;
          TmsData.push(data);
        });
      }
      if (this.type === "restroom") {
        tmsData.forEach((tms) => {
          let data: any = {
            name: "",
            place_name: "",
            plus_code: "",
            description: "",
            type: "",
            opening_time: "",
            closing_time: "",
            weekend_opening_time: "",
            weekend_closing_time: "",
            address: "",
            city: "",
            country: "",
            google_address: "",
            house_number: "",
            active: true,
            category_name: this.categoryName,
            longitude: "",
            latitude: "",
            landmark: "",
            mobile_number: "",
            state: "",
            street: "",
            title: "",
            zip: "",
            geom: {
              latitude: 0,
              longitude: 0,
            },
          };
          let opening_time = null;
          let closing_time = null;

          data.name = tms.name ? tms.name : null;
          data.place_name = tms.place_name ? tms.place_name : null;
          data.type = tms.type ? tms.type.toUpperCase() : null;
          data.description = tms.description ? tms.description : null;

          data.mobile_number = tms.mobile_number ? tms.mobile_number : null;
          data.plus_code = tms.plus_code ? tms.plus_code : null;
          if (tms.opening_time) {
            data.opening_time = tms.opening_time;
          }
          if (tms.closing_time) {
            data.closing_time = tms.closing_time;
          }

          data.weekend_opening_time = tms.weekend_opening_time
            ? tms.weekend_opening_time
            : null;
          data.weekend_closing_time = tms.weekend_closing_time
            ? tms.weekend_closing_time
            : null;
          data.address = tms.address ? tms.address : null;
          data.city = tms.city ? tms.city : null;
          data.google_address = tms.google_address ? tms.google_address : null;
          data.country = tms.country ? tms.country : null;
          data.house_number = tms.house_number ? tms.house_number : null;
          data.landmark = tms.landmark ? tms.landmark : null;
          data.latitude = tms.latitude ? tms.latitude : null;
          data.longitude = tms.longitude ? tms.longitude : null;
          data.geom.latitude = tms.latitude ? tms.latitude : null;
          data.geom.longitude = tms.longitude ? tms.longitude : null;
          data.state = tms.state ? tms.state : null;
          data.street = tms.street ? tms.street : null;
          data.title = tms.title ? tms.title : null;
          data.zip = tms.zip ? tms.zip : null;
          data.active = tms.active
            ? tms.active.toLowerCase() === "true"
              ? true
              : false
            : false;
          TmsData.push(data);
        });
      }
      if (this.type === "brand") {
        tmsData.forEach((tms) => {
          // tms.geom =
          //   tms.latitude && tms.longitude
          //     ? { longitude: tms.longitude, latitude: tms.latitude }
          //     : null;
              TmsData.push(tms);
        });
      }
      if(this.type==="store"){
        tmsData.forEach((tms) => {
          tms.geom =
            tms.latitude && tms.longitude
              ? { longitude: tms.longitude, latitude: tms.latitude }
              : null;
              TmsData.push(tms);
        });
      }
      
      if (this.type === "vehicle") {
        tmsData.forEach((tms) => {
          tms.registration_from = tms.registration_from
            ? moment(tms.registration_from).format("YYYY-MM-DD")
            : null;
          tms.registration_to = tms.registration_to
            ? moment(tms.registration_to).format("YYYY-MM-DD")
            : null;
          tms.insurance_from = tms.insurance_from
            ? moment(tms.insurance_from).format("YYYY-MM-DD")
            : null;
          tms.insurance_to = tms.insurance_to
            ? moment(tms.insurance_to).format("YYYY-MM-DD")
            : null;
          tms.pollution_expiry = tms.pollution_expiry
            ? moment(tms.pollution_expiry).format("YYYY-MM-DD")
            : null;
          tms.fitness_expiry = tms.fitness_expiry
            ? moment(tms.fitness_expiry).format("YYYY-MM-DD")
            : null;
          tms.model = tms.model ? moment(tms.model).format("YYYY") : null;
          tms.manufacturing_year = tms.manufacturing_year
            ? moment(tms.manufacturing_year).format("YYYY")
            : null;
            TmsData.push(tms);
        });
      }
      if (this.type === "restroom_category" || this.type === "restroom") {
        this.totalCount = TmsData.length;
        this.manualRouteTableSource.load(TmsData);
      } else {
        this.totalCount = tmsData.length;
        this.manualRouteTableSource.load(tmsData);
      }
    };
    reader.readAsBinaryString(file);
    this.validate = !this.validate;
  }

  closeDialog() {
    this.manualRouteTableSource = new LocalDataSource();
    this.ref.close();
  }

  async uploadFile() {
    if (this.type === "holiday_list") {
      await this.http.create(
        this.manualRouteTableSource.data,
        {},
        "holiday_list",
        "attendance"
      );
      this.closeDialog();
      this.toaster.showToast(
        `${this.type}s details saved successfully`,
        "Success",
        false
      );
    } else {
      if (this.type === "roster") {
        const updatedResponse = [];
        const zonePromises = [];

        for (const item of this.response) {
          const unique_id = item.unique_id;
          zonePromises.push(
            this.http.query({ __unique_id__equal: unique_id }, "zone", "auth")
          );
        }
        const zoneResponses = await Promise.all(zonePromises);
        
        for (const res of zoneResponses) {
          res.data.forEach((element) => {
            const newObj = {
              ...element,
              zone_id: element.id,
            };
            updatedResponse.push(newObj);
          });
        }
        for (
          let i = 0;
          i < this.response.length && i < updatedResponse.length;
          i++
        ) {
          this.response[i].zone_id = updatedResponse[i].id;
        }

        var uploadRes = await this.http.createDirect(
          this.response,
          {},
          `bulk_upload`,
          "attendance"
        );
      } else {
        var uploadRes = await this.http.createDirect(
          this.manualRouteTableSource.data,

          {},
          `bulk_upload/${this.type}`,
          "auth"
        );
      }
      this.taskID = uploadRes.task_id;
      const response = this.nbDialogService
        .open(CeleryTaskStatusDialogComponent, {
          context: {
            taskId: this.taskID,
            title: `Adding ${this.type}s`,
            componentName: "bulk-upload",
          },
        })
        .onClose.subscribe((res) => {
          if (res === true) {
            this.closeDialog();
            this.toaster.showToast(
              `${this.type}s details saved successfully`,
              "Success",
              false
            );
          }
        });
    }  
    
  }

  async validateFile() {
    if(this.type==="holiday_list"){
      let showErrorToast=false;
          this.manualRouteTableSource.data.forEach((item) => {
            if(item.holiday_name===null||item.holiday_name==="Field may not be null."|| item.holiday_name===""||item.holiday_name===undefined ){
              item.holiday_name="Field may not be null.";
              this.toaster.showToast(
                `${this.type}s details can not be saved, Please fill all the Fields`,
                "Error",
                true
              );
              this.upload = false;
              showErrorToast=true;

            }else if(item.holiday_date===null||item.holiday_date==="Field may not be null."|| item.holiday_date===""||item.holiday_date===undefined){
              item.holiday_date==="Field may not be null."
              this.toaster.showToast(
                `${this.type}s details can not be saved, Please fill all the Fields`,
                "Error",
                true
              );
              this.upload = false;
              showErrorToast=true;

            }
          });
          if(!showErrorToast){
            this.upload = true;
            this.toaster.showToast(
              `${this.type}s details validated successfully`,
              "Success",
              false); 
          }
       this.manualRouteTableSource.load(this.manualRouteTableSource.data);

          
    
          // this.upload = true;
          // this.toaster.showToast(
          //   `${this.type}s details validated successfully`,
          //   "Success",
          //   false
          // );
        
  }else{
    try {
      if(this.type==='roster'){
        const errorMessage = {
          title: '',
          message: '',
          error: false,
        };
        let conditionsMet = true;
        this.response.forEach(obj => {
          obj.time_slots.forEach(row => {
              switch(row.day.toLowerCase()) {
                  case 'sunday':
                      row.day = 0;
                      break;
                  case 'monday':
                      row.day = 1;
                      break;
                  case 'tuesday':
                      row.day = 2;
                      break;
                  case 'wednesday':
                      row.day = 3;
                      break;
                  case 'thursday':
                      row.day = 4;
                      break;
                  case 'friday':
                      row.day = 5;
                      break;
                  case 'saturday':
                      row.day = 6;
                      break;
                  default:
                      row.day = -1;
                      break;
              }

              if(row.start_time_1 && row.end_time_1 && row.end_time_1_extended_next_day){
                row.end_time_2=null
                row.start_time_2=null
                row.end_time_3=null
                row.start_time_3=null
              }
              if(row.start_time_2 && row.end_time_2 && row.end_time_2_extend_next_day){
                row.end_time_3=null
                row.start_time_3=null
              }
              try {
                if (!row.is_week_off) {
                  if ((row.start_time_1 || row.end_time_1) && !(row.start_time_1 && row.end_time_1)) {
                    conditionsMet=false
                    errorMessage.message = 'Please provide both timings for SHIFT 1.';
                    errorMessage.title = 'Error in ' + this.days[row['day']];
                    errorMessage.error = true;
                    return errorMessage
                    
                  }
                  if ((row.start_time_2 || row.end_time_2) && !(row.start_time_2 && row.end_time_2)) {
                    conditionsMet=false
                    errorMessage.message = 'Please provide both timings for SHIFT 2.';
                    errorMessage.title = 'Error in ' + this.days[row['day']];
                    errorMessage.error = true;
                    return errorMessage
                    
                  }
                  if ((row.start_time_3 || row.end_time_3) && !(row.start_time_3 && row.end_time_3)) {
                    conditionsMet=false
                    errorMessage.message = 'Please provide both timings for SHIFT 3.';
                    errorMessage.title = 'Error in ' + this.days[row['day']];
                    errorMessage.error = true;
                    return errorMessage
                    
                  }
                  if (!row.start_time_1 && !row.start_time_2 && !row.end_time_1 && !row.end_time_2) {
                    conditionsMet=false
                    errorMessage.message = 'No SHIFT timings provided.';
                    errorMessage.title = 'Error in ' + this.days[row['day']];
                    errorMessage.error = true;
                    return errorMessage
                   
                  }
                  if (
                    (row.start_time_1 === row.end_time_1 &&
                      row.start_time_1 &&
                      row.end_time_1) ||
                    (row.start_time_2 === row.end_time_2 &&
                      row.start_time_2 &&
                      row.end_time_2) ||
                    (row.start_time_3 === row.end_time_3 &&
                      row.start_time_3 &&
                      row.end_time_3)
                  ) {
                    conditionsMet=false
                    errorMessage.message = 'SHIFT can not have same start & end time.';
                    errorMessage.title = 'Error in ' + this.days[row['day']];
                    errorMessage.error = true;
                    return errorMessage
                   
                  }
                  
                  if (!row.start_time_1 && !row.end_time_1 &&
                      ((row.start_time_2 && row.end_time_2) || ((row.start_time_3 && row.end_time_3)))) {
                        conditionsMet=false
                        errorMessage.message = 'Please provide SHIFT 1 first.';
                        errorMessage.title = 'Error in ' + this.days[row['day']];
                        errorMessage.error = true;
                        return errorMessage
                       
                  }
                  if (!row.start_time_2 && !row.end_time_2 && (row.start_time_3 && row.end_time_3)) {
                    conditionsMet=false
                    errorMessage.message = 'Please provide SHIFT 2 first.';
                        errorMessage.title = 'Error in ' + this.days[row['day']];
                        errorMessage.error = true;
                        return errorMessage
                  }
                  if (row.start_time_2 < row.end_time_1) {
                    if(row['start_time_2_extend_next_day']) {
                      return;
                    }
                    else{
                      conditionsMet=false
                      errorMessage.message = 'SHIFT 1 & 2 are overlapping!.';
                        errorMessage.title = 'Error in ' + this.days[row['day']];
                        errorMessage.error = true;
                        return errorMessage
                  }
                }
                  if (row.start_time_3 < row.end_time_2) {
                    if(row['start_time_3_extend_next_day']) {
                      return;
                    }
                    else{
                      conditionsMet=false
                      errorMessage.message = 'SHIFT 2 & 3 are overlapping!.';
                      errorMessage.title = 'Error in ' + this.days[row['day']];
                      errorMessage.error = true;
                      return errorMessage
                  }
                  }
                }
                if ((row.start_time_1 > row.end_time_1 && !row.end_time_1_extended_next_day)||(row.start_time_2 > row.end_time_2 && !row.end_time_2_extend_next_day)||(row.start_time_3 > row.end_time_3 && !row.end_time_3_extend_next_day) ) {
                  if(row.end_time_1_extended_next_day || row.end_time_2_extend_next_day || row.end_time_3_extend_next_day) {
                    return;
                  } else {
                    conditionsMet=false
                    errorMessage.message = 'SHIFT end time should be greater than start time. Please check "Is Extended Next Day?"';
                      errorMessage.title = 'Error in ' + this.days[row['day']];
                      errorMessage.error = true;
                      return errorMessage
                  }
                }
                
                if ((row.start_time_1 < row.end_time_1 && row.end_time_1_extended_next_day) ||
                (row.start_time_2 < row.end_time_2 && row.end_time_2_extend_next_day) ||
                (row.start_time_3 < row.end_time_3 && row.end_time_3_extend_next_day)) {
                  conditionsMet=false
                  errorMessage.message = 'Shift timing must not be greater than 24 hours';
                      errorMessage.title = 'Error in ' + this.days[row['day']];
                      errorMessage.error = true;
                      return errorMessage

        }
                for (let i = 0; i < obj.time_slots.length - 1 ; i++) {
                  const currentDay = obj.time_slots[i];
                  const nextDay = obj.time_slots[i + 1];
                  if (
                      (currentDay.start_time_1 && currentDay.end_time_1 && currentDay.end_time_1_extended_next_day) ||
                      (currentDay.start_time_2 && currentDay.end_time_2 && currentDay.end_time_2_extend_next_day) ||
                      (currentDay.start_time_3 && currentDay.end_time_3 && currentDay.end_time_3_extend_next_day)
                  ) {
                      if (
                        (nextDay.start_time_1 < currentDay.end_time_1) ||
                        (nextDay.start_time_1 < currentDay.end_time_2) ||
                        (nextDay.start_time_1 < currentDay.end_time_3)
                    ) {
                       conditionsMet=false
                       errorMessage.message = 'Start time of the next day should be greater than end time of the current day for day';
                      errorMessage.title = 'Error in ' + this.days[nextDay['day']];
                      errorMessage.error = true;
                      return errorMessage
                    }
              }
            }
        
                if(!row.start_time_2 && !row.end_time_2 && !row.start_time_3 && !row.end_time_3 && row.end_time_3_extend_next_day ){
                  conditionsMet=false
                  errorMessage.message = 'Please Provide Shift Time. Please check "Is Extended Next Day?"';
                  errorMessage.title = 'Error in ' + this.days[row['day']];
                  errorMessage.error = true;
                  return errorMessage
          
                }
                if(!row.start_time_3 && !row.end_time_3 && row.end_time_3_extend_next_day ){
                  conditionsMet=false
                  errorMessage.message = 'Please Provide Shift Time. Please check "Is Extended Next Day?"';
                  errorMessage.title = 'Error in ' + this.days[row['day']];
                  errorMessage.error = true;
                  return errorMessage
                }
                return errorMessage
              } catch (error) {
                console.log(error)
              }
          });
          
      });
      if (errorMessage.error) {
        this.toaster.showToast(errorMessage.message,
            errorMessage.title, true);
        return;
    }
      if(conditionsMet){
      var res = await this.http.createDirect(
        this.response,
        {},
        `bulk_upload_validation`,
        "attendance"
      );
    }
    }else{
      var res = await this.http.createDirect(
        this.manualRouteTableSource.data,
        {},
        `bulk_upload_validation/${this.type}`,
        "auth"
      );
    }

      if (res.status === "success") {
        if (this.manualRouteTableSource.data.length > 0) {
          this.upload = true;
          this.toaster.showToast(
            `${this.type}s details validated successfully`,
            "Success",
            false
          );
        } else {
          this.toaster.showToast(
            `${this.type}s details can not be saved, Please add all the Fields`,
            "Error",
            true
          );
        }
      }
    } catch (err) {
      this.toaster.showToast(
        `${this.type}s details can not be validated`,
        "Error",
        true
      );
      const errObject = err.error.message;
      let obj;
      // tslint:disable-next-line:ban no-eval
      eval("obj=" + errObject);
      for (const property in obj) {
        if (obj.hasOwnProperty(property)) {
          for (const p in obj[property]) {
            if (obj[property].hasOwnProperty(p)) {
              if (p === "geom") {
                for (const geom in obj[property][p]) {
                  this.manualRouteTableSource.data[property][geom] =
                    obj[property][p][geom];
                }
              } else {
                this.manualRouteTableSource.data[property][p] =
                  obj[property][p];
              }
            }
          }
        }
      }
      this.manualRouteTableSource.load(this.manualRouteTableSource.data);
}}
  }
   rosterBulk(arr?) {
    const result = [];
    const res= arr
    let found = -1;
    arr.forEach((obj, i) => {
        if (obj.name) {
            found = result.length;
            const desired = {
                zone_name:res[i].zone_name || "",
                unique_id:res[i].unique_id || "",
                description: res[i].description || "",
                name: res[i].name || "",
                time_slots: [{
                    day: res[i].day,
                    start_time_1: res[i].start_time_1,
                    end_time_1: res[i].end_time_1,
                    end_time_1_extended_next_day:Boolean(res[i].end_time_1_extended_next_day.toLowerCase() === 'true'),
                    start_time_2: res[i].start_time_2,
                    end_time_2: res[i].end_time_2,
                    end_time_2_extend_next_day:Boolean(res[i].end_time_2_extend_next_day.toLowerCase() === 'true'),
                    start_time_3:res[i].start_time_3,
                    end_time_3:res[i].end_time_3,
                    end_time_3_extend_next_day:Boolean(res[i].end_time_3_extend_next_day.toLowerCase() === 'true'),
                    is_week_off:Boolean(res[i].is_week_off.toLowerCase() === 'true')
                }]
            };
            result.push(desired); 
        } else if (found !== -1) {
            result[found].time_slots.push({
              day: res[i].day,
              start_time_1: res[i].start_time_1,
              end_time_1: res[i].end_time_1,
              end_time_1_extended_next_day:Boolean(res[i].end_time_1_extended_next_day.toLowerCase() === 'true'),
              start_time_2: res[i].start_time_2,
              end_time_2: res[i].end_time_2,
              end_time_2_extend_next_day:Boolean(res[i].end_time_2_extend_next_day.toLowerCase() === 'true'),
              start_time_3:res[i].start_time_3,
              end_time_3:res[i].end_time_3,
              end_time_3_extend_next_day:Boolean(res[i].end_time_3_extend_next_day.toLowerCase() === 'true'),
              is_week_off:Boolean(res[i].is_week_off.toLowerCase() === 'true')
            });
        }
    });
    this.response=result
    return result;
}

  onEditConfirm(event) {
    if (window.confirm("Are you sure you want to save?")) {
      let active;
      if (typeof event.newData.active === "string") {
        active = event.newData.active.toLowerCase() === "true" ? true : false;
      } else {
        active = event.newData.active ? true : false;
      }
      event.newData.active = active;
      event.confirm.resolve(event.newData);
      this.upload = false;
      this.validate = !this.validate;
    } else {
      event.confirm.reject();
      this.validate = !this.validate;
    }
  }

  onEdit(event, validate): void {
    
  }

  switchToEdit(event) {
    this.table.grid.getSelectedRows().forEach((row) => {
      this.table.grid.edit(row);
      this.validate = !this.validate;
      this.upload = false;
    });
  }

  onCustomEvent(event) {
    switch (event.action) {
      case "documento":
        //this.doSomething(event.data);
        break;
      case "editar":
        //this.anotherFunction(event.data);
        break;
    }
  }
}
