import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {DataService} from '../../../@core/utils';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import { link, unlink } from 'fs';
import { log } from 'console';

@Component({
    selector: 'ngx-type-ahead',
    templateUrl: './type-ahead.component.html',
    styleUrls: ['./type-ahead.component.scss'],
})
export class TypeAheadComponent implements AfterViewInit, OnInit, OnChanges {
    spinner: boolean = false;
    data$: Promise<any[]>;
    selectedZone: any = [];
    textForCheckbox: any;
    dataInput$ = new Subject<string>();
    dataLoading = false;
    searchFailed: boolean = true;
    @Input()inputForRider: any;
    @Input() model: any;
    @Input() inputForCreateEditRoster: any;
    @Input() multiple: boolean = false;
    @Input() riderManage: boolean = false;
    @Input() clearAfterSelection: boolean = false;
    @Input() disabled: boolean = false;
    @Input() filters: any;
    @Input() required = true;
    @Input() basePath: any = 'auth';
    @Input() placeholder: string = this.translateText('Search Here');
    @Input() url: string;
    @Input() inputForZoneSchedule: string;
    @Input() uniqueInputForHoliday: string;
    @Input() linkedZonesForCheckbox: any[];
    @Input() text: string = 'name';
    @Input() dataValue: any;
    @Input() value: string;
    @Input() searchField: string;
    @Input() notifySearchFail: boolean = false;
    @Input() displayNames: string[] = null;
    @Input() selectedItems = [];
    @Input() selectedOptions:[];
    @Input() autoRouteFc: (id: any) => void;
    @Input() autoType = false;
    @Input() show = true;
    @Input() failedEmit: boolean = false;
    @Output() searchTerm: EventEmitter<string> = new EventEmitter();
    @Output() send: EventEmitter<any> = new EventEmitter();
    @Output() push:EventEmitter<any>=new EventEmitter();
    @Output() failed: EventEmitter<string> = new EventEmitter();
    @Output() cleared: EventEmitter<string> = new EventEmitter();
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('selectContent') selectContent: any;
    finalLinkedZones: any=[];
    userStartedTyping: boolean=false;
    placeholderForDropdown: string;

    constructor(private translate: TranslateService
        , private http: DataService, private cd: ChangeDetectorRef) {
    }

    ngOnInit() {
        // const riderFilter = document.querySelector('.ng-select .ng-select-container');
        // const riderFilterContainer = document.querySelector('.ng-value-container');
        // if (riderFilter) {
        //     this.renderer.setStyle(riderFilter, 'overflow', 'unset');
        // } else if (riderFilterContainer) {
        //     this.renderer.setStyle(riderFilterContainer, 'height', '44px');
        // }
        this.selectedZone = [];
        this.finalLinkedZones=[];
        this.textForCheckbox='Select All'
        this.getPlaceholder()
    }
    getPlaceholder() {
        this.placeholderForDropdown=this.userStartedTyping ? '' : 'Search zones here';

    }
    translateText(key: string): string {
        let translation: string;
        this.translate.get(key).subscribe((res: string) => {
            translation = res;
        });
        return translation;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(this.url==='schedule' && this.inputForZoneSchedule==='zoneSchedule'){
        if (changes.filters) {
            const previousFilters = changes.filters.previousValue;
            const currentFilters=changes.filters.currentValue;
            if(currentFilters&&currentFilters['__zone_id__equal']){
                this.disabled=false;
            }
            else if (previousFilters && previousFilters['__zone_id__equal'] !== undefined||null) {
                this.disabled=true;
            } 
        }
    }
    
        if (changes?.failedEmit) {
            if (changes?.failedEmit.currentValue === true) {
                this.selectedItems = [];
            }
        }
    }

    ngAfterViewInit() {
        this.cd.detectChanges();
    }

    async searchApi(event): Promise<any[]> {
        let query = {};
        query['__limit'] = 80;
        if (this.searchField) {
            query[this.searchField] = event;
        } else {
            if (/^\d+$/.test(event)) {
                query['__phone__contains'] = event;
                query['__mobile_number__contains'] = event;
            } else {
                if (this.url === 'vehicle') {
                    query['__registration_number__contains'] = event;
                } else if (this.url === 'category' && this.riderManage) {
                    query['__limit'] = 300;
                    query['__name__contains'] = event;
                } else if (this.url === 'user_zone') {
                    query['__limit'] = 1500;
                } else if (this.url === 'reason') {
                    query['__limit__equal'] = 80;
                } else if (this.url === 'zone_schedule') {
                    query['__name__contains'] = event;
                } else {
                    query['__name__contains'] = event;
                }
            }
        }

        for (const i in this.filters) {
            if (this.filters.hasOwnProperty(i)) {
                query[i] = this.filters[i];
            }
        }
        try {
            return (await this.http.query(query, this.url, this.basePath)).data;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    emitSelected(event) {
        this.failedEmit = false;
        if (typeof event === typeof 'str' || !event) {
            this.failed.emit(event);
            return;
        }
        if (this.autoType) {
            this.autoRouteFc(event.id);
        }

        this.send.emit(event);
        this.searchFailed = true;
        this.onChange.emit(event);
        if (this.clearAfterSelection) this.selectContent.clearItem(event);
        this.cd.detectChanges();
    }
   

   

    selectAllZones() {
        this.spinner = true;
        this.data$.then(async (res: any[]) => {
            for (const r of res) {
                this.send.emit(r);
                await this.delay(500);
            }
            this.spinner = false;
        });
    }

    async delay(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    resultFormatter(x: {
        id: any
        zone_name: string;
        name: string;
        mobile_number: string;
        registration_number?: string;
        financial_year_start?: string;
    }) {
        try {
            if (this.displayNames && this.displayNames.length) {
                if (this.basePath === 'attendance') {
                    return this.displayNames.map((d) => x[d]).join('- ');
                } else {
                    return this.displayNames.map((d) => x[d]).join(', ');
                }
            }
            if(this.url==='rider_availability'){
                 return x.name + ' - ' + x.mobile_number;
            }
            if (this.url === "schedule"||this.url==="route") {
              return x.name;
            }
          
            if (x.zone_name) {
                return x.zone_name ? x.zone_name : x.id;

            }
            if (x.mobile_number) {
                return x.name + ' - ' + x.mobile_number;
            }
            if (x.registration_number) {
                return x.registration_number;
            }
            if (x.financial_year_start) {
                return x.financial_year_start;
            }
            return x.name ? x.name : x.registration_number;
        } catch (error) {
        }
    }

    selectAll(checked) {
        if (checked === true) {
          this.textForCheckbox="Unselect All"
            this.data$.then(items => {
                this.selectedZone = [...items];
                this.selectContent.writeValue(this.selectedZone);
                this.send.emit(this.selectedZone);
                this.searchFailed = true;
                this.onChange.emit(this.selectedZone);
                if (this.clearAfterSelection) this.selectContent.clearItem(this.selectedZone);
                this.cd.detectChanges();
            });
        } else if (checked === false) {
          this.textForCheckbox="Select All"
            this.selectedZone = [];
            this.selectContent.writeValue(this.selectedZone);

            this.send.emit(this.selectedZone);
            this.searchFailed = true;
            this.onChange.emit(this.selectedZone);
            if (this.clearAfterSelection) this.selectContent.clearItem(this.selectedZone);
            this.cd.detectChanges();
        }
    }

    async toggleZoneSelection(event: Event, item: any) {
        
         const input = event.target as HTMLInputElement;
         if (input.checked) {
             this.selectedZone = [...this.selectedZone, item];
 
         } else {
             this.selectedZone = this.selectedZone.filter(zone => zone.id !== item.id);
         }
        
     
         this.selectedZone = this.selectedZone.filter(
             (value, index, self) => self.findIndex((v) => v.id === value.id) === index
         );
     
         this.failedEmit = false;
         if (typeof event === typeof 'str' || !event) {
             this.failed.emit(this.selectedZone);
             return;
         }
         if (this.autoType) {
             this.autoRouteFc(this.selectedZone.id);
         }
         this.send.emit(this.selectedZone);
         this.searchFailed = true;
         this.onChange.emit(this.selectedZone);
         if (this.clearAfterSelection) this.selectContent.clearItem(this.selectedZone);
        
         this.cd.detectChanges();
    }

    toggleZone(event, item) {
        if(item){
            this.selectedZone.push(item)
        }

        this.selectedZone = this.selectedZone.filter(
            (value, index, self) => self.findIndex((v) => v.id === value.id) === index
        );

        this.send.emit(this.selectedZone)
        if (this.uniqueInputForHoliday) {
            this.selectContent.close();

        }
    }
    
    toggleEditRosterZoneSelection(event,item){
        this.selectedZone=[]
        if(item){
            if(item){
                this.selectedZone.push(item)
            }
        this.send.emit(this.selectedZone)

        }
        if (this.inputForCreateEditRoster) {
            this.selectContent.close();

        }
    }
 
    toggleDivSelection(event: Event, item: any) {
        
        const isChecked = this.isSelected(item);
        if (!isChecked) {
            this.selectedZone.push(item);
        } else {
            this.selectedZone = this.selectedZone.filter(zone => zone.id !== item.id);
            
        }
        this.selectedZone = this.selectedZone.filter(
            (value, index, self) => self.findIndex((v) => v.id === value.id) === index
        );
        this.send.emit(this.selectedZone);
    }
    
    isSelected(item: any): boolean {
        return this.selectedZone.some(zone => zone.id === item.id); // Assuming 'id' is a unique property
    }

    isSelectedForCheckbox(item: any): boolean {
        
        return this.linkedZonesForCheckbox.some(zone => zone.id === item.id); // Assuming 'id' is a unique property
    }
    

    async loadPeople() {
        try {
            this.data$ = this.searchApi(undefined);
        } catch (e) {
        }
        this.dataInput$
            .pipe(
                distinctUntilChanged(),
                debounceTime(700),
                tap(() => {
                    this.dataLoading = true;
                }),
                map(async (term) => {
                    this.spinner = true;
                    return this.searchApi(term);
                }),
            )
            .subscribe(
                async (res) => {
                    this.data$ = res;
                    await res;
                    this.spinner = false;
                    this.dataLoading = false;
                },
                () => {
                    this.spinner = false;
                    this.dataLoading = false;
                },
            );
    }

    inputInit(event) {
        if(event.term){
            this.userStartedTyping = true;
            this.getPlaceholder();
        }else{
            this.userStartedTyping=false;
            this.getPlaceholder()
        }
        
        // init api call for blank input
        if (event.term === '') {
            try {
                this.data$ = this.searchApi(undefined);
            } catch (e) {
            }
        }
    }
}
