import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import {NB_AUTH_OPTIONS, NbAuthService, NbLoginComponent} from '@nebular/auth';
import {Router} from '@angular/router';
import {ConstantsService} from '../../@core/utils/constants.service';
import {TranslateService} from '@ngx-translate/core';
import { lang } from 'moment';
import { NbLayoutDirectionService, NbLayoutDirection } from '@nebular/theme';

@Component({
	selector: 'ngx-login-page',
	templateUrl: './login-page.component.html',
	styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent extends NbLoginComponent {
	showPassword = false;
	languageCode:string;
	languageList = [    { code: 'en', label: 'English' }, { code: 'vi', label: 'Vietnamese' }, {code:'ar', label:'Arabic'}];
	selectedLanguage: any;
	sliderUrl: any;

	constructor(service: NbAuthService, @Inject(NB_AUTH_OPTIONS) options: {}, cd: ChangeDetectorRef, router: Router,
				public constantsService: ConstantsService,private layoutDirectionService: NbLayoutDirectionService,
				public translate: TranslateService) {
		super(service, options, cd, router);
		this.languageCode = localStorage.getItem('languageCode')||'en';
		this.selectedLanguage = this.languageList.find(language => language.code === this.languageCode)?.label;
         this.sliderUrl={
			'slider1':this.constantsService.login_slide_1,
			'slider2':this.constantsService.login_slide_2,
			'slider3':this.constantsService.login_slide_3
		 }
		if (this.languageCode) {
			translate.setDefaultLang(this.languageCode);
			translate.use(this.languageCode);
		}

	this.convertLang(this.languageCode);

	}

    updateLogoURL($event) {
		$event.target.src = this.constantsService.roadcastURL;
    }

	getInputType() {
		if (this.showPassword) {
			return 'text';
		}
		return 'password';
	}

	toggleShowPassword() {
		this.showPassword = !this.showPassword;
	}

	convertLang(code: string): void {
        localStorage.setItem('languageCode', code);
        this.translate.setDefaultLang(code);
        this.translate.use(code);
		const htmlElement = document.documentElement;
		if(code === 'ar'){
			this.layoutDirectionService.setDirection(NbLayoutDirection.RTL);
			document.body.classList.add('rtl');
			document.body.classList.remove('ltr');
		}else{
			this.layoutDirectionService.setDirection(NbLayoutDirection.LTR);
			document.body.classList.add('ltr');
			document.body.classList.remove('rtl');
		}
    }
}
