import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CheckForUpdateService {

  constructor(appRef: ApplicationRef, public swUpdate: SwUpdate) {
    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
    const everySixHours$ = interval( 1 * 60 * 1 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
    everySixHoursOnceAppIsStable$.subscribe(() => this.swUpdate.checkForUpdate());

    this.swUpdate.available.subscribe(event => {
      console.error('current version is: ', event.current);
      console.error('available version is: ', event.available);
      this.promptUser();
    });

  }

  private promptUser(): void {
    console.error('---updating to new version---');
    this.swUpdate.activateUpdate().then(() => document.location.reload());
  }
}

