import {Component, Input, OnInit} from '@angular/core';
import {DataService, ToastService} from '../../../@core/utils';
import {Category} from '../../../@core/models';
import {Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "ngx-category-selection",
  templateUrl: "./category-selection.component.html",
  styleUrls: ["./category-selection.component.scss"],
})
export class CategorySelectionComponent implements OnInit {
  @Input() serial: string;
  @Input() userId: string;
  @Input() selectedCategory: Category[] = [];
  @Input() primaryMessage: boolean = false;

  categories: any[] = [];

  constructor(
    private http: DataService,
    private toaster: ToastService,
    private router: Router,
    private translate: TranslateService
  ) {}
  translateText(key: string): string {
    let translation: string;
    this.translate.get(key).subscribe((res: string) => {
      translation = res;
    });
    return translation;
  }
  // ngOnInit() {
  // 	this.getCategories();
  // }
  //
  //
  // getCategories() {
  // 	this.categories = this.http.query({__active__bool: true}, 'category', 'auth');
  // }
  //
  // checkStatus(id: string): boolean {
  // 	return this.selectedCategory ? this.selectedCategory.findIndex(c => c.id === id) > -1 : false;
  // }
  //
  // async changeStatus(id: number, event: any) {
  // 	try {
  // 		await this.http.create({__action: event ? 'add' : 'remove', category_id: id, rider_id: this.userId}, {},
  // 			'rider_category', 'auth');
  // 		this.toast.showToast('Successfully ' + event ? 'added' : 'removed' + ' category', 'Success', false);
  // 	} catch (e) {
  // 		this.toast.showToast('Error adding category ',
  // 			'Error', true, e);
  // 	}
  //
  // }

  ngOnInit() {
    if (this.userId) {
      this.getCategories().then();
    }
  }

  async getCategories() {
    try {
      this.categories = (
        await this.http.query(
          { __rider_id__equal: this.userId },
          "rider_category",
          "auth"
        )
      ).data;
    } catch (e) {}
  }

  async addCat(event) {
    try {
      const objectEvent = {};

      const noPrimaryCategory = !this.categories.some(
        (category) => category.is_primary === true
      );

      if (noPrimaryCategory) {
        objectEvent["is_primary"] = true;
        await this.http.create(
          {
            __action: "add",
            category_id: event.id,
            rider_id: this.userId,
            is_primary: true,
          },
          {},
          "rider_category",
          "auth"
        );
      } else {
        await this.http.create(
          { __action: "add", category_id: event.id, rider_id: this.userId },
          {},
          "rider_category",
          "auth"
        );
      }

      objectEvent["category"] = event;
      this.categories.push(objectEvent);

      this.toaster.showToast(
        this.translateText("Successfully create category."),
        "Success",
        false
      );
    } catch (e) {
      this.toaster.showToast(
        this.translateText("Error adding category "),
        "Error",
        true,
        e
      );
    }
  }

  async removeCat(id: string, index: number) {
    try {
      const categoryToRemove = this.categories[index];
      const isPrimary = categoryToRemove.is_primary === true;
      if (isPrimary) {
        this.toaster.showToast(
          this.translateText(
            "Removed a primary category. Add a category to set it as primary."
          ),
          "Success",
          false
        );
        await this.http.create(
          { __action: "remove", category_id: id, rider_id: this.userId },
          {},
          "rider_category",
          "auth"
        );
      } else {
        await this.http.create(
          { __action: "remove", category_id: id, rider_id: this.userId },
          {},
          "rider_category",
          "auth"
        );
        this.toaster.showToast(
          this.translateText("Successfully removed category."),
          "Success",
          false
        );
      }
      this.categories.splice(index, 1);
    } catch (e) {
      this.toaster.showToast(
        this.translateText("Error removing category"),
        "Error",
        true,
        e
      );
    }
  }

  editCat(id?: string) {
    this.router
      .navigate(["/pages/management/base/category/" + (id ? id : "new")])
      .then();
  }
}
