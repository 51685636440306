import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
// tslint:disable-next-line: max-line-length
import {NbLogoutComponent} from '@nebular/auth';
import {LoginPageComponent} from './login-page/login-page.component';
import {AuthComponent} from './auth.component';
import {RegisterComponent} from './register/register.component';
import {RequestPasswordComponent} from './request-password/request-password.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';


export const routes: Routes = [
	{
		path: '',
		component: AuthComponent,  // <---
		children: [
			{
				path: '',
				component: LoginPageComponent,
			},
			{
				path: 'login',
				component: LoginPageComponent,
			},
			{
				path: 'register',
				component: RegisterComponent,
			},
			{
				path: 'logout',
				component: NbLogoutComponent,
			},
			{
				path: 'request-password',
				component: RequestPasswordComponent,
			},
			{
				path: 'reset-password',
				component: ResetPasswordComponent,
			},
		],
	},

];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AuthRoutingModule {

}

