import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Address} from '../../../@core/models';
import {DataService, ToastService} from '../../../@core/utils';
import {Geom} from '../../../@core/models/user';
import {ajax} from 'rxjs/ajax';

@Component({
	selector: 'ngx-address',
	templateUrl: './address.component.html',
	styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit, OnChanges {

	@Input()
	address: Address;
	@Input()
	id: string;
	@Input()
	path: string;
	@Input()
	serial: string;
	@Input()
	title: string = 'Address';
	@Input()
	moreDetails: string;
	@Output()
	addressSaved: EventEmitter<any> = new EventEmitter<any>();
	@Output()
	updatedAddress: EventEmitter<any> = new EventEmitter<any>();
	addressRef: Address;


	constructor(private http: DataService, private toaster: ToastService) {
	}

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!this.address) {
			this.address = <Address>{geom: {} as Geom};
		} else {
			this.addressRef = this.address;
		}
	}

	async save() {
		this.addressSaved.emit(true);
		this.updatedAddress.emit(this.address);
		try {
			// if (this.address.id) {
			// 	await this.http.update(this.address.id, this.address, {}, 'address', 'auth');
			// } else {
			// 	const res = await this.http.create(this.address, {__only: 'id'}, 'address', 'auth');
			// 	this.address.id = res[0].id;
			// 	await this.http.update(this.id, {address_id: this.address.id}, {}, this.path ? this.path : 'outlet', 'auth');
			// }
			
			// this.toaster.showToast('Saved address successful', 'Success', false);
		} catch (e) {
			// this.toaster.showToast('Error saving address', 'Error', true, e);
		}
	}

	setAddress(event: Address) {
		this.address.google_address = event.google_address;
		this.address.geom.latitude = event.geom.latitude;
		this.address.geom.longitude = event.geom.longitude;
		this.address.city = event.city;
		this.address.state = event.state;
		this.address.country = event.country;
	}

	async getaddressLatLng() {
		const address = await this.getAddressGeo(this.address.geom.latitude, this.address.geom.longitude);
		this.address.google_address = address[0].display_name;
	}
	async getAddressGeo(lat, lng) {
		const arr = [];
		arr.push({id: 0, lat: lat, lng: lng});
		return new Promise(resolve => ajax.post('https://api-track.roadcast.co.in/reverse', arr,
			{'content-type': 'application/json'}).subscribe(res => resolve(res.response)));
	}

}
