import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NbAuthModule} from '@nebular/auth';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import { NbSelectModule } from '@nebular/theme';

import {
    
    NbAlertModule,
    NbButtonModule,
    NbCheckboxModule, NbFormFieldModule, NbIconModule,
    NbInputModule,
    NbLayoutModule,
    NbSpinnerModule,
} from '@nebular/theme';

import {LoginPageComponent} from './login-page/login-page.component';
import {RegisterComponent} from './register/register.component';
import {RequestPasswordComponent} from './request-password/request-password.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {AuthComponent} from './auth.component';
import {NgbCarouselModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AuthRoutingModule} from './auth-routing.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
	declarations: [AuthComponent, LoginPageComponent, RegisterComponent, RequestPasswordComponent, ResetPasswordComponent],
    imports: [
        CommonModule,
        NbSelectModule,
        FormsModule,
        RouterModule,
        NbAlertModule,
        NbInputModule,
        NbButtonModule,
        NbCheckboxModule,
        TranslateModule,
        AuthRoutingModule,
        NbLayoutModule,
        NgbModule, NgbCarouselModule,
        NbAuthModule,
        NbSpinnerModule, NbFormFieldModule, NbIconModule,
    ],
})
export class AuthModule {
}
