import {Component, Input, OnInit} from '@angular/core';
import {DataService, ToastService} from '../../../@core/utils';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'ngx-rider-list', templateUrl: './rider-list.component.html', styleUrls: ['./rider-list.component.scss'],
})
export class RiderListComponent implements OnInit {

    @Input() enable: boolean = false;
    @Input() serial: string = '1';
    @Input() users: any[] = [];
    @Input() zoneId: string;
    @Input() catId: string;
    @Input() vehicleId: number;
    @Input() isAdmin: boolean;
    @Input() isCat: boolean = false;
    @Input()
        // isRider: boolean;
    filters: any;
    infoPopup = 'If unable to Add Riders, Create Zone First (Step 1)';

    constructor(private http: DataService, private toaster: ToastService, private router: Router,
                private translate: TranslateService) {
    }
    translateText(key: string): string {
        let translation: string;
        this.translate.get(key).subscribe((res: string) => {
            translation = res;
        });
        return translation;
    }
    ngOnInit() {
        if (this.zoneId) {
            this.filters = {__only: ['id', 'name', 'mobile_number'], __zone_id__ne: this.zoneId, __is_active__bool: 'true'};
        } else if (this.catId) {
            this.filters = {
                __only: ['id', 'name', 'mobile_number'], __category_id__equal: this.catId, __is_active__bool: 'true',
            };
        } else if (this.vehicleId) {
            this.filters = {
                __only: ['id', 'name', 'mobile_number'], __vehicle_id__equal: this.vehicleId, __is_active__bool: 'true',
            };
        }
        this.getUser().then();
    }

    async getUser() {
        let query = {};
        if (this.zoneId) {
            query = {
                __zone_id__equal: this.zoneId, user: true, __limit: 100,
            };
        } else if (this.catId) {
            query = {__category_id__equal: this.catId, user: true, __limit: 100};
        }
        let users;
        try {
            if (this.zoneId) {

                users = await this.http.query(query, 'rider', 'auth');

            } else if (this.catId) {
                users = await this.http.query(query, 'rider_category', 'auth');
            }
            if (users.data && users.data.length) {

                this.users = users.data;
            } else {
                this.users = [];
            }
        } catch (e) {

        }
    }


    async addUser(event) {
        if (!this.vehicleId && this.zoneId) {
            try {
                await this.http.update(event.id, {
                    zone_id: this.zoneId,
                }, {}, 'rider', 'auth');
                this.users.push(event);
                this.toaster.showToast('Successfully added user.', 'Success', false);
                this.getUser().then();
            } catch (e) {
                this.toaster.showToast('Error adding user ', 'Error', true, e);
            }
        } else if (!this.zoneId && this.vehicleId) {
            try {
                await this.http.update(event.id, {vehicle_id: this.vehicleId}, {}, 'rider', 'auth');
                this.users.push(event);
                this.toaster.showToast('Successfully added user.', 'Success', false);
            } catch (e) {
                this.toaster.showToast('Error adding user ', 'Error', true, e);
            }
        } else if (this.catId) {
            try {
                await this.http.create({
                    __action: 'add', category_id: this.catId, rider_id: event.id,
                }, {}, 'rider_category', 'auth');
                this.users.push(event);
                this.getUser().then();

                this.toaster.showToast(this.translateText('Successfully added user.'), 'Success', false);
            } catch (e) {
                this.toaster.showToast(this.translateText('Error adding user.'), 'Error', true, e);
            }
        } else {
            this.toaster.showToast(this.translateText('Error adding user: Please fill details first. '), 'Error', true);
        }

    }

    async removeUser(id: string, index: number) {
        try {
            if (this.vehicleId !== undefined || this.zoneId) {
                const query = {};
                if (this.zoneId) {
                    query['zone_id'] = null;
                } else {
                    query['vehicle_id'] = null;
                }
                await this.http.update(id, query, {}, 'rider', 'auth');
            } else if (this.catId) {
                await this.http.create({__action: 'remove', rider_id: id}, {}, 'rider_category', 'auth');
            }
            this.users.splice(index, 1);
            this.toaster.showToast(this.translateText('Successfully removed rider.'), 'Success', false);
        } catch (e) {
            this.toaster.showToast(this.translateText('Error removing rider '), 'Error', true, e);
        }
    }

    editUser(id ?: number) {
        this.router.navigate(['/pages/management/base/rider/' + (id ? id : 'new')]);
    }

}
