import {Component, Input, OnInit} from '@angular/core';
import moment from "moment/moment";

@Component({
  selector: 'ngx-device-details',
  templateUrl: './device-details.component.html',
  styleUrls: ['./device-details.component.scss'],
})
export class DeviceDetailsComponent implements OnInit {

  @Input()
  deviceDetails: any;
  @Input() lastLogin: any;

  @Input()
  titlePrefix: any = '';

  constructor() { }

  ngOnInit() {
  }

  getLastLogin() {
    if (this.lastLogin?.last_login_at) {
      // return moment(this.lastLogin.last_login_at).utc().toString().slice(3, 25);
      return moment(this.lastLogin.last_login_at).local().format('DD-MM-YYYY hh:mm:ss');
    } else {
      return '--';
    }
  }
}