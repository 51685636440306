import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {NbDateService, NbPopoverDirective} from '@nebular/theme';
import {DateTimeRangePickerService} from "./date-time-range-picker.service";

@Component({
    selector: 'ngx-date-time-range-picker',
    templateUrl: './date-time-range-picker.component.html',
    styleUrls: ['./date-time-range-picker.component.scss'],
})
export class DateTimeRangePickerComponent implements OnInit {

    @Output() rangeEmitter: EventEmitter<any> = new EventEmitter();
    @Output() customRange: EventEmitter<boolean> = new EventEmitter();
    @Input()
    todayAllowed = true;
    @Input('customDateRange') customDateRange: any[] = []
    selectedCustomRange: Date[];
    defaultDate: Date;
    selectedDate = 'Pick Date';
    myOptions = {
        exclusiveEndDates: true,
    };
    range = {
        start: moment().startOf('day').format('YYYY-MM-DDTHH:mm'),
        end: moment().format('YYYY-MM-DDTHH:mm'),
    };
    maxDate = moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DDTHH:mm');
    @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;

    // @ViewChild('InputElement', {static : true}) myDiv: ElementRef<HTMLElement>;
    constructor(protected dateService: NbDateService<Date>, private cd: ChangeDetectorRef, private dateTimeService: DateTimeRangePickerService) {
        this.defaultDate = this.dateService.today();
        this.defaultDate.setHours(0, 0, 0);
    }

    ngOnInit(): void {
        if (this.todayAllowed) {
            this.maxDate = moment().format('YYYY-MM-DDTHH:mm');
        }

        this.dateTimeService.customDateRangePicker.subscribe(res => {
            this.selectedDate = moment(res[0]).format('DD-MM-YYYY HH:mm');
            this.selectedDate += ' - ' + moment(res[1]).format('DD-MM-YYYY HH:mm');
        });
        this.fillDateLabel();
    }


    emitToday(): void {
        this.range = {
            start: moment().startOf('day').format('YYYY-MM-DDTHH:mm'),
            end: moment().format('YYYY-MM-DDTHH:mm'),
        };
        this.rangeEmitter.emit(this.range);
        this.fillDateLabel();
        this.dateTimeService.showPath.next(true);
        this.popover.hide();
    }

    emitYesterday(): void {
        this.range = {
            start: moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DDTHH:mm'),
            end: moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DDTHH:mm'),
        };
        this.rangeEmitter.emit(this.range);
        this.fillDateLabel();
        this.dateTimeService.showPath.next(true);
        this.popover.hide();
    }



    emitCustomRange(): void {
        this.customRange.emit(true);
        // this.dateTimeService.showPath.next(true);
        this.popover.hide();
    }


    fillDateLabel(): void {
        this.selectedDate = moment(this.range.start).format('DD-MM-YYYY HH:mm');
        this.selectedDate += ' - ' + moment(this.range.end).format('DD-MM-YYYY HH:mm');
    }

}
