import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NbAuthJWTToken, NbAuthModule, NbPasswordAuthStrategy} from '@nebular/auth';
import {NbRoleProvider, NbSecurityModule} from '@nebular/security';

import {throwIfAlreadyLoaded} from './module-import-guard';
import {AnalyticsService, RoleProvider} from './utils';
import {environment} from '../../environments/environment';

const socialLinks = [
	{
		url: 'https://github.com/akveo/nebular',
		target: '_blank',
		icon: 'github',
	},
	{
		url: 'https://www.facebook.com/akveo/',
		target: '_blank',
		icon: 'facebook',
	},
	{
		url: 'https://twitter.com/akveo_inc',
		target: '_blank',
		icon: 'twitter',
	},
];

const DATA_SERVICES = [];


export const NB_CORE_PROVIDERS = [
	...DATA_SERVICES,
	...NbAuthModule.forRoot({

		strategies: [
			NbPasswordAuthStrategy.setup({
				name: 'email',
				baseEndpoint: environment.auth,
				login: {
					// ...
					endpoint: 'login',
					method: 'post',
				},
				token: {
					class: NbAuthJWTToken,
					key: 'token',
				},
				logout: {
					endpoint: 'logout/',
					method: 'get',
				},
                refreshToken: {
                    endpoint: 'refresh-token',
                    method: 'post',
                    requireValidToken: true,
                    redirect: {
                        success: null,
                        failure: null,
                    },
                    defaultErrors: [ 'Something went wrong, please try again.' ],
                    defaultMessages: [ 'Your token has been successfully refreshed.' ],
                },
				requestPass: {
					endpoint: 'forgot_password',
					defaultErrors: ['Something went wrong, please try again.'],
					defaultMessages: ['Reset password instructions have been sent to your email.'],
				},
				resetPass: {
					endpoint: 'reset_password',
					method: 'post',
					resetPasswordTokenKey: 'reset_password_token',
					requireValidToken: true,
					defaultErrors: ['Something went wrong, please try again.'],
					defaultMessages: ['Your password has been successfully changed.'],
				},
			}),
		],
		forms: {
			login: {
				socialLinks: socialLinks,
			},
			register: {
				socialLinks: socialLinks,
			},
		},
	}).providers,

	NbSecurityModule.forRoot({
		accessControl: {
			guest: {
				view: '*',
			},
			user: {
				parent: 'guest',
				create: '*',
				edit: '*',
				remove: '*',
			},
		},
	}).providers,

	{
		provide: NbRoleProvider, useClass: RoleProvider,
	},
	AnalyticsService,
];

@NgModule({
	imports: [
		CommonModule,
	],
	exports: [
		NbAuthModule,
	],
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		throwIfAlreadyLoaded(parentModule, 'CoreModule');
	}

	static forRoot(): ModuleWithProviders<CoreModule> {
		return {
			ngModule: CoreModule,
			providers: [
				...NB_CORE_PROVIDERS,
			],
		};
	}
}
