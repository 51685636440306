import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import {NB_AUTH_OPTIONS, NbAuthService, NbLoginComponent} from '@nebular/auth';
import {Router} from '@angular/router';
import {ConstantsService} from '../../@core/utils/constants.service';
import {TranslateService} from '@ngx-translate/core';
import { lang } from 'moment';

@Component({
	selector: 'ngx-login-page',
	templateUrl: './login-page.component.html',
	styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent extends NbLoginComponent {
	showPassword = false;
	languageCode:string;
	languageList = [    { code: 'en', label: 'English' }, { code: 'vi', label: 'Vietnamese' }];
	selectedLanguage: any;
	sliderUrl: any;

	constructor(service: NbAuthService, @Inject(NB_AUTH_OPTIONS) options: {}, cd: ChangeDetectorRef, router: Router,
				public constantsService: ConstantsService,
				public translate: TranslateService) {
		super(service, options, cd, router);
		this.languageCode = localStorage.getItem('languageCode')||'en';
		this.selectedLanguage = this.languageList.find(language => language.code === this.languageCode)?.label;
         this.sliderUrl={
			'slider1':this.constantsService.login_slide_1,
			'slider2':this.constantsService.login_slide_2,
			'slider3':this.constantsService.login_slide_3
		 }
		console.log(this.selectedLanguage,'122')
		if (this.languageCode) {
			translate.setDefaultLang(this.languageCode);
			translate.use(this.languageCode);
		}

	}

    updateLogoURL($event) {
		$event.target.src = this.constantsService.roadcastURL;
    }

	getInputType() {
		if (this.showPassword) {
			return 'text';
		}
		return 'password';
	}

	toggleShowPassword() {
		this.showPassword = !this.showPassword;
	}

	convertLang(code: string): void {
		console.log(code,'122')
		localStorage.setItem('languageCode', code);
		console.log(code);
		this.translate.setDefaultLang(code);
		this.translate.use(code);
		console.log(code);
	}

}
