import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ajax} from 'rxjs/ajax';

@Injectable({
	providedIn: 'root',
})
export class CountriesService {

	url: string = 'https://raw.githubusercontent.com/sagarshirbhate/Country-State-City-Database/master/Contries.json';
	country_url = 'https://raw.githubusercontent.com/praneshsaminathan/country-state-city/master/countries.json';
	state_url = 'https://raw.githubusercontent.com/praneshsaminathan/country-state-city/master/states.json';
	cities_url = 'https://raw.githubusercontent.com/praneshsaminathan/country-state-city/master/countries.json';

	constructor(private http: HttpClient) {
	}

	// allCountries(): Observable<any>{
	//   return this.http.get(this.url);

	// }

	allCountries(): Promise<any> {
		return new Promise(resolve => ajax.get(this.url, {}).subscribe(res => resolve(res.response)));
	}

	allCountry(): Promise<any> {
		return new Promise(resolve => ajax.get(this.country_url, {}).subscribe(res => resolve(res.response)));
	}

	allStates(): Promise<any> {
		return new Promise(resolve => ajax.get(this.state_url, {}).subscribe(res => resolve(res.response)));
	}

	allCity(): Promise<any> {
		return new Promise(resolve => ajax.get(this.cities_url, {}).subscribe(res => resolve(res.response)));
	}
}
