import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';

import {NbAuthService, NbAuthToken} from '@nebular/auth';
import {NbRoleProvider} from '@nebular/security';
import {map} from 'rxjs/operators';

@Injectable()
export class RoleProvider implements NbRoleProvider {

	constructor(private authService: NbAuthService) {
	}

	getRole(): Observable<string> {
		return this.authService.onTokenChange()
			.pipe(
				map((token: NbAuthToken) => {
					try {
						return JSON.parse(token.getValue())[1];
					} catch (e) {
						return 'guest';
					}

				}),
			);
	}
}
