import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { DataService, ToastService } from '../../../@core/utils';
import { TranslateService } from "@ngx-translate/core";
import { Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { OrderOptimizationService } from '../../../pages/order-optimization/order-optimization.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { v4 as uuidv4 } from 'uuid';

@Component({
	selector: 'ngx-dialog',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
	@Input() message: any
	@Input() header: any
	@Input() selectedTableSettings: any;
	@Input() selectedTableSource: any;
	@Input() zoneId: any;
	@Input() selectedRows: any;
	@Input() manualRouteData: any;
	@Input() manualRouteTableSource: any;
	@Input() tmsData: any;
	@Input() excelUpload: boolean

	@Input() title: string;
	@Input() body: string;
	@Input() type: string;
	@Input() scheduleId: string;
	// @Input() rider_id:string;
	@Input() name: string;
	@Input() data: any;
	tableData: any;
	riderAssigned: boolean = false;
	reorderMade: boolean = false;
	isEditable:boolean =false
	originalData: any = [];
	fixedData: any=[];
	columns: any[] = []
	selectedItems: any[] = [];
	showAddOrderFeatures: boolean = false;
	orderText: string = 'Change Order Priority Move the order up or down to change the sequence'
	additionalColumns: any[] = []
	editingRow: any;
	deleteOrderItem: any=[];
	approveData: any=[];
	eventForRider: any;
	constructor(private dialogService: NbDialogService, private messageService: MessageService, private confirmationService: ConfirmationService, private orderOptimizationService: OrderOptimizationService, private http: DataService, protected ref: NbDialogRef<DialogComponent>, private toast: ToastService,
		private translate: TranslateService, private router: Router,
		private toaster: ToastService) {
	}
	ngOnInit() {
		this.columns = [
			{ field: 'sNo', header: 'S. No.' },
			{ field: 'touch_point.name', header: 'Touchpoint Name' },
			{ field: 'delivery_address.address', header: 'Delivery Address' },
			{ field: 'touch_point_id', header: 'TouchPoint Id' },
		];
		this.additionalColumns = [
			{ field: 'delivery_address.pin_code', header: this.translateText('PinCode') },
			// {field:'weight',header:'weight'},
			{ field: 'order_type', header: this.translateText('Order Type') },
			{ field: 'total', header: this.translateText('Total') },
			{ field: 'mode_of_payment', header: this.translateText('Mode Of Payment') },
			{ field: 'touch_point.mobile_number', header: this.translateText('Phone Number') },
			{ field: 'touch_point.customer_type', header: this.translateText('Customer Type') },
			{ field: 'actions', header: this.translateText('Actions') }

		]

		if (this.data) {
			this.tableData = JSON.parse(JSON.stringify(this.data))
			this.fixedData=JSON.parse(JSON.stringify(this.data));
			this.approveData=JSON.parse(JSON.stringify(this.data))
		}
	}

	translateText(key: string): string {
		let translation: string;
		this.translate.get(key).subscribe((res: string) => {
			translation = res;
		});
		return translation;
	}
	dismiss() {
		this.ref.close();
	}
	dismissPermission(value) {
		this.ref.close(value);
	}

	reject(reason) {
		this.toast.showToast(this.translateText('Leave Rejected'), 'Rejected', true);
		this.ref.close({ 'status': 'reject', 'notes': reason });
	}

	approve(reason) {
		this.toast.showToast(this.translateText('Leave Approved'), 'Success', false);
		this.ref.close({ 'status': 'approve', 'notes': reason });
	}

	confirm() {
		this.ref.close(true);
	}

	decline() {
		this.ref.close(false);
	}

	async submit(name, scheduleId, type: string) {
		if (this.type === 'copy') {
			type = 'copy'
			this.toast.showToast(this.translateText('Schedule Copied Successfully'), 'Success', false);
			await this.router.navigateByUrl('/pages/roster/new', { state: { scheduleId: scheduleId, type: type } });
			this.ref.close(name)
		}
		if(this.type==='delete'){
			type='delete'
			const respone=  await this.http.delete(scheduleId, {}, 'schedule', 'attendance');
			this.toast.showToast(this.translateText('Schedule Deleted Successfully'), 'Success', false);
			await this.router.navigateByUrl('/pages/roster/list', { state: { scheduleId: scheduleId,type:type} });
            this.ref.close(name)
		}
		else{
		this.ref.close(name);
		}
	}


	closeDialog() {
		if (this.eventForRider) {
			const { name: selectedRiderName, mobile_number: selectedRiderNumber } = this.eventForRider;

			const updatedSelectedRows = this.selectedRows.map(row => ({
				...row,
				'FE Name': selectedRiderName,
				'FE Number': selectedRiderNumber
			}));
			if (this.excelUpload) {
				this.manualRouteData = JSON.parse(JSON.stringify(this.tmsData));
			}

			updatedSelectedRows.forEach(updatedRow => {
				this.manualRouteTableSource.getAll().then((rows => {
					const index = rows.findIndex(row => row['Shipment Ids'] === updatedRow['Shipment Ids']);
					rows[index]['FE Name'] = updatedRow['FE Name'];
					rows[index]['FE Number'] = updatedRow['FE Number'];
					this.manualRouteTableSource.update(rows[index], updatedRow);
				}))
			});
		}
		this.ref.close(this.riderAssigned);
	}
	clear(){
		this.riderAssigned = false;
	}

	async assignRider(event: any) {
		this.eventForRider=event;
		this.riderAssigned = true;

	}
	goBack(){
		this.showAddOrderFeatures=false;
		this.selectedItems=[]
		this.tableData.touch_points = [...this.fixedData.touch_points];
		this.orderText = 'Change Order Priority Move the order up or down to change the sequence'

		const additionalFields = new Set(this.additionalColumns.map(col => col.field));
		this.columns = this.columns.filter(col => !additionalFields.has(col.field));
	}


	async toggleOrderFeatures() {
		let skipCount = 0;

		this.showAddOrderFeatures = !this.showAddOrderFeatures;
		this.selectedItems = JSON.parse(JSON.stringify(this.tableData.touch_points))
		if (this.showAddOrderFeatures) {
			this.orderText = 'Pending Orders'
			const currentLength = this.tableData.touch_points.length;

			const response = await this.orderOptimizationService.getOrderFromDB();
			
			const normalizedData = response.data.map((item, index) => ({
				touch_point: { touch_point_id: item?.touch_point_id, name: item?.name, customer_type: item?.customer_type, mobile_number: item?.phone, },
				delivery_address: { address: item.address, pin_code: item?.pin_code },
				mode_of_payment: item?.mode_of_payment,
				total: item?.total,
				order_type: item?.order_type,
				delivery_location: { latitude:item.latitude, longitude:item.longitude },
				id:uuidv4(),
				
			}));

			const existingExternalIds = this.tableData.touch_points.map(item => item.touch_point_id);

			let newData = normalizedData.filter(item => !existingExternalIds.includes(item.touch_point_id));
			if (!this.columns.find(col => col.field === 'actions')) {
					this.columns = [...this.columns, ...this.additionalColumns];
	
				}
			if(newData.length>0){
				// const outlets = newData.map((x, index) => {
				// 	if (!(x["latitude"] && x["longitude"])) {
				// 	  return {
				// 		external_id: x["touch_point"]["external_id"],
				// 		address: x["delivery_address"]["address"],
				// 		skip: false,
				// 	  };	
				// 	} else {
				// 	  skipCount += 1;
				// 	  return {
				// 		external_id: x["touch_point"]["external_id"],
				// 		address: x["delivery_address"]["address"],
				// 		skip: true,
				// 	  };
				// 	}
				//   });
				//   console.log(outlets)
				// const locForDBorder=await this.orderOptimizationService.getOutletGeom(outlets);

				newData = newData.map((item, index) => {
					// const { latitude, longitude } = locForDBorder.response[index].geom;
					return {
					  ...item,
					  priority: currentLength + index + 1,
					};
				  });
				  
				this.tableData.touch_points = [...this.tableData.touch_points, ...newData];
	
				
			}
			
		}
	}

	addPendingOrders() {
		this.orderText = 'Change Order Priority Move the order up or down to change the sequence'
		this.tableData.touch_points = JSON.parse(JSON.stringify(this.selectedItems))
		this.fixedData.touch_points=JSON.parse(JSON.stringify(this.tableData.touch_points))
		this.selectedItems = []
		const additionalFields = new Set(this.additionalColumns.map(col => col.field));
		this.columns = this.columns.filter(col => !additionalFields.has(col.field));
		this.showAddOrderFeatures = false;
	}

	// async onRowReorder(event: any) {
	// 	const { dragIndex, dropIndex } = event;
		
	// 	if (dragIndex !== undefined && dropIndex !== undefined && dragIndex !== dropIndex) {
	// 	  this.reorderMade = true;
	// 	  await this.moveItemInArray(this.tableData.touch_points, dragIndex, dropIndex);
	// 	  await this.updatePriorities();
	// 	}
	//   }
	  
	//   async moveItemInArray(array: any[], fromIndex: number, toIndex: number) {
	// 	console.log(fromIndex,toIndex,'122')
	// 	if (fromIndex < 0 || fromIndex >= array.length || toIndex < 0 || toIndex >= array.length) {
	// 		console.log('running')
	// 	  return;
	// 	}
	// 	console.log(array,'122')
	// 	const itemToMove = array[fromIndex];
	// 	console.log(itemToMove,'122')
	// 	// Remove item from original position
	// 	array.splice(fromIndex, 1);
	
	// 	// Insert item at new position
	// 	array.splice(toIndex, 0, itemToMove);
	
	// 	// Assuming tableData.touch_points is updated directly (if not, assign it back)
	// 	this.tableData.touch_points = array;
	
	
	// 	console.log(this.tableData.touch_points,'122')
	//   }
	  
	//   async updatePriorities() {
	// 	this.tableData.touch_points.forEach((item, index) => {
	// 	  item.priority = index + 1;
	// 	});
	//   }
	  


	async changePriority() {
		try {
			if (this.tableData.touch_points.length < this.approveData.touch_points.length) {
				const uniqueExternalIds = new Set(this.deleteOrderItem.map(item => item.touch_point_id));
			    const missingItems = this.approveData.touch_points.filter(fixedItem => 
					!this.tableData.touch_points.some(tableItem => 
						tableItem.touch_point_id === fixedItem.touch_point_id
					) && !uniqueExternalIds.has(fixedItem.touch_point_id)
				);
				
				this.deleteOrderItem.push(...missingItems);
				missingItems.forEach(item => uniqueExternalIds.add(item.touch_point_id));
			}
			
			
			this.tableData.touch_points.forEach((item, index) => {
				item.priority = index + 1;
				item['touch_point_id']=item.touch_point_id;
				item.order_type=item.order_type.toUpperCase();
			});
			const updatedTouchPoints = this.tableData.touch_points.map(({ id,delivery_address,delivery_location,order_type,touch_point_id,total,mode_of_payment, priority,touch_point }) => ({  id,delivery_address,delivery_location,order_type,touch_point_id,total,mode_of_payment, priority,touch_point }));
			const updatedTableData = {
				...this.tableData,
				touch_points: updatedTouchPoints
				
			};

			if(this.deleteOrderItem.length>0){
				this.deleteTouchPoint(this.deleteOrderItem)
			}
			const res = await this.http.update(this.tableData.id, updatedTableData, {}, 'order', 'order');
			console.log(res);
			if (res.success) {
				this.ref.close(this.tableData);
			}
		} catch (error) {
			this.toast.showToast(('Error!'), 'Success', true);
			this.ref.close(false);
			console.error('An error occurred while changing priority:', error);
		}
	}
	onRowUnselect(event: any) {

	}

	onRowSelect(event: any) {

	}


	onRowEditInit(item: any) {
		this.isEditable=true
		// Store a copy of the original data before editing starts
		this.editingRow=item;
		
		this.originalData[item.id] = JSON.parse(JSON.stringify(item));
		console.log('Editing started for:', item);
	}

	onRowEditSave(item: any) {
		this.isEditable = false
		console.log(item);
		this.editingRow = null;
		if (!this.selectedItems.some(selectedItem => selectedItem.id === item.id)) {			
			return;
		}

		// Find the index of the item in the touch_points array
		const index = this.tableData.touch_points.findIndex(tp => tp.id === item.id);
		if (index !== -1) {
			// Replace the item in the touch_points array with the updated item
			this.selectedItems[index] = item;
			console.log('Item saved successfully:', item);
			// this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Item saved successfully' });
		} else {
			console.error('Error saving item: Item not found');
			// this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Item could not be saved' });
		}
	}


	validateRow(item: any): boolean {
		return item.touch_point.name && item.delivery_address.address && item.touch_point_id
	}

	onRowEditCancel(item: any, index: number) {
		this.editingRow=null;
		this.isEditable=false
		console.log(item);
		console.log(this.originalData);
		if (this.originalData[item.id]) {
			// Restore the original data for the item
			this.tableData.touch_points[index] = JSON.parse(JSON.stringify(this.originalData[item.id]));
			// Remove the original data from the storage
			delete this.originalData[item.id];
		}
		console.log('Editing cancelled for:', item);
	}

	deleteOrder(item: any) {
		const dialogRef = this.dialogService.open(DialogComponent, {
			context: {
				message: `Are you sure you want to delete ${item.touch_point.name}?`,
				header: 'Confirm',
				type: 'deleteTouchPoint'
			}
		});
	
		dialogRef.onClose.subscribe(async (confirmed: boolean) => {
			if (confirmed) {
				const index = this.tableData.touch_points.indexOf(item);
				if (index !== -1) {
					try {
						if(item.touch_point_id==null||!item.hasOwnProperty('touch_point_id')){
							this.toast.showToast('First Add TouchPoint To Order.', 'Error', true);
							return;
							}

						this.deleteOrderItem.push(item);
						this.tableData.touch_points.splice(index, 1);
						this.selectedItems.splice(index, 1);
						this.toast.showToast('Temporarily Deleted ', 'Success', false);
					} catch (error) {
						console.error('Delete order error:', error);
							this.toast.showToast('Failed to delete order. Please try again.', 'Error', true);
						
					}
				}
			}
		});
	}
	async deleteTouchPoint(item:any){
		item.forEach(async (del:any)=>{
			await this.http.create({order_id:this.tableData.id,touch_point_id:del.touch_point_id} ,{}, 'delete_touchpoint', 'order');

		})
		this.toast.showToast('Permanently Deleted ', 'Success', false);


	}
}

