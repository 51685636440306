import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, interval, Subject, Subscription} from 'rxjs';
import {DataService} from '../../@core/utils';
import * as moment from 'moment/moment';

@Injectable({
  providedIn: 'root',
})
export class MapService implements OnDestroy {

  devicesSubject$: Subject<{ devices: any[], reEmitToMap: boolean }> = new Subject() as Subject<{ devices: any[],
    reEmitToMap: boolean }>;
  deviceStatsSubject$: Subject<{ stats: any }> = new Subject() as Subject<{ stats: any }>;
  devicesToUpdateMarkersSubject$: Subject<{ devices: any }> = new Subject() as Subject<{ devices: any }>;

  vehiclesSubject$: Subject<{ vehicles: any[], reEmitToMap: boolean }> = new Subject() as Subject<{ vehicles: any[],
    reEmitToMap: boolean }>;
  vehicleStatsSubject$: Subject<{ stats: any }> = new Subject() as Subject<{ stats: any }>;
  vehiclesToUpdateMarkersSubject$: Subject<{ vehicles: any }> = new Subject() as Subject<{ vehicles: any }>;

  zoneSelectedSubject$: Subject<{ zoneList: any }> = new Subject() as Subject<{ zoneList: any }>;

  sub: Subscription;
  query: any = {};
  queryVehicle: any = {};
  currentDate: Date = new Date();
  devices: any[] = [];
  vehicles: any[] = [];
  devicesToUpdateMarkers: any[] = [];
  vehiclesToUpdateMarkers: any[] = [];
  zoneList: any[] = [];
  deviceStats: any = {
    onDuty: 0,
    offDuty: 0,
    free: 0,
    idle: 0,
  };
  vehicleStats: any = {
    onDuty: 0,
    offDuty: 0,
    free: 0,
    idle: 0,
  };
  filterDeviceList: any = {
    viewAll: true,
    onDuty: true,
    offDuty: true,
    free: true,
    idle: true,
    inactive: true,
    onOrder: true,
    inZone: true,
    lowBattery: true,
  };
  filterVehicleList: any = {
    viewAll: true,
    onDuty: true,
    offDuty: true,
    free: true,
    idle: true,
    inactive: true,
    onOrder: true,
    inZone: true,
    lowBattery: true,
  };
  selectedTab = 'Riders';
  updatedDevice = [];
  isPolling = new BehaviorSubject<boolean>(false);
  selectedRider: any;
  selectedVehicle: any;
  vehicleRefresh = new BehaviorSubject<boolean>(false);
  selectVehicle: boolean = false;
  constructor(private http: DataService) { }

  async init() {

    this.query = {};

    if (!this.filterDeviceList.viewAll) {
      if (this.filterDeviceList.onDuty) {
        this.query['__on_duty__bool'] = true;
      }

      if (this.filterDeviceList.offDuty) {
        this.query['__on_duty__bool'] = false;
      }

      if (this.filterDeviceList.free) {
        this.query['__status__equal'] = 'FREE';
      }


      if (this.filterDeviceList.idle) {
        this.query['__is_idle__bool'] = true;
      }


      if (this.filterDeviceList.inactive) {
        const now = new Date();
        now.setMinutes(now.getMinutes() - 60);
        this.query['__fix_time__lte'] = now;
      }


      if (this.filterDeviceList.onOrder) {
        this.query['__status__ne'] = 'FREE';
      }


      if (this.filterDeviceList.inZone) {
        this.query['__in_zone__bool'] = true;
      }

      if (this.filterDeviceList.lowBattery) {
        this.query['__battery__lt'] = 0.25;
      }
    }

    let zones = '';
    this.zoneList.forEach(zone => {
      if (zones === '' ) {
        zones += zone.id;
      } else {
        zones += ',' + zone.id;
      }
    });
    if (zones !== '' ) {
      if (this.selectedTab === 'Riders') {
        this.query['__zone_id__in'] = zones;
      } else {
        this.query['__zone_ids__array_contains'] = zones;
      }
    }

const query = {
    __include: ['name', 'mobile_number'], __order_by: ['-updated_on'],
    __limit: 500,
    __active__bool: true,
    __updated_on__datetime_gte: moment(new Date()).subtract(23, 'hours').subtract(59, 'minutes').toJSON(),
    // __updated_on__datetime_lte: new Date().toJSON(),
  };

    this.destroy();
    await this.loadNext(query);
    await this.loadNextVehicles();
    this.sub = interval(15000)
        .subscribe(() => {
          if (this.selectedTab === 'Riders') {
            this.getUpdateDevices();
          } else if (this.selectedTab === 'Vehicles') {
            this.getUpdateVehicles();
          }
        });
  }

  async loadNext(query: any) {
    try {

      // const query = {
      //   __include: ['name', 'mobile_number'], __order_by: ['-updated_on'],
      //   __limit: 500,
      //   __active__bool: true,
      //   __updated_on__datetime_gte: moment(new Date()).subtract(23, 'hours').subtract(59, 'minutes').toJSON(),
      //   __updated_on__datetime_lte: new Date().toJSON(),
      // };

      for (const queryKey in this.query) {
        if (this.query.hasOwnProperty(queryKey)) {
          query[queryKey] = this.query[queryKey];
        }
      }


      const devices = await this.http.query(query, 'rider_availability', 'order');
      if (devices.data && devices.data.length) {
        this.devices = devices.data;
      } else {
        this.devices = [];
      }
    } catch (e) {
      this.devices = [];
    }

    this.devicesSubject$.next({devices: this.devices, reEmitToMap: true });
    this.calculateStatsAndEmit().then();
  }

  async loadNextVehicles() {
    try {

      const query = {
        __include: ['name', 'mobile_number'], __order_by: ['-updated_on'],
        __limit: 500,
      };

      for (const queryKey in this.query) {
        if (this.query.hasOwnProperty(queryKey)) {
          query[queryKey] = this.query[queryKey];
        }
      }


      const vehicles = await this.http.query({}, 'vehicle_availability', 'order');
      if (vehicles.data && vehicles.data.length) {
        this.vehicles = vehicles.data;
        this.vehiclesSubject$.next({vehicles: this.vehicles, reEmitToMap: true });
        this.calculateStatsAndEmit().then();
      } else {
        this.vehicles = [];
      }
    } catch (e) {
      this.vehicles = [];
    }
  }


  async calculateStatsAndEmit() {
    this.deviceStats = {
      onDuty: 0,
      offDuty: 0,
      free: 0,
      idle: 0,
    };
    this.vehicleStats = {
      onDuty: 0,
      offDuty: 0,
      free: 0,
      idle: 0,
    };
    this.devices.forEach(device => {
      if (device.on_duty) {
        this.deviceStats.onDuty += 1;
      } else {
        this.deviceStats.offDuty += 1;
      }

      if (device.is_idle) {
        this.deviceStats.idle += 1;
      }

      if (device.status === 'FREE') {
        this.deviceStats.free += 1;
      }

    });
    this.deviceStatsSubject$.next({stats: this.deviceStats});
  }


  async getUpdateDevices() {
    const currentTime: Date = new Date();
    this.currentDate.setSeconds(this.currentDate.getSeconds() - 5);
    try {

      const query = {
        __order_by: ['-updated_on'], __updated_on__datetime_gte: this.currentDate.toJSON(),
        __limit: 500,
        __active__bool: true,
      };

      for (const queryKey in this.query) {
        if (this.query.hasOwnProperty(queryKey)) {
          query[queryKey] = this.query[queryKey];
        }
      }

      const devices = await this.http.query(query, 'rider_availability', 'order');

      this.updatedDevice = devices.data;
      this.currentDate = currentTime;
      this.devicesToUpdateMarkers = [];
      if (devices && devices.hasOwnProperty('data')) {
        devices.data.forEach(d => {
          const device = this.devices.findIndex(dev => dev.id === d.id);
          if (device > -1) {

            this.calculateStatsAndEmit().then();

            const updateMarker = true;

            this.devices[device].fix_time = d.fix_time;
            this.devices[device].battery = d.battery;
            this.devices[device].gps_status = d.gps_status;
            this.devices[device].on_duty = d.on_duty;
            this.devices[device].updated_on = d.updated_on;
            this.devices[device].has_charging = d.has_charging;
            this.devices[device].current_location.latitude = d.current_location.latitude;
            this.devices[device].current_location.longitude = d.current_location.longitude;
            if (updateMarker) {
              this.devicesToUpdateMarkers.push(this.devices[device]);
            }

          }
        });
        this.devicesSubject$.next({devices: this.devices, reEmitToMap: false });

        if (this.devicesToUpdateMarkers.length > 0) {
          this.devicesToUpdateMarkersSubject$.next({ devices: this.devicesToUpdateMarkers});
        }
      }

    } catch (e) {
      console.error(e);
    }

  }

  async getUpdateVehicles() {
    const currentTime: Date = new Date();
    this.currentDate.setSeconds(this.currentDate.getSeconds() - 5);
    try {

      const query = {
        __order_by: ['-updated_on'], __updated_on__datetime_gte: this.currentDate.toJSON(),
        __limit: 500,
      };

      for (const queryKey in this.query) {
        if (this.query.hasOwnProperty(queryKey)) {
          query[queryKey] = this.query[queryKey];
        }
      }

      const vehicleData = await this.http.query(query, 'vehicle_availability', 'order');
      this.currentDate = currentTime;
      this.vehiclesToUpdateMarkers = [];
      if (vehicleData && vehicleData.hasOwnProperty('data')) {
        vehicleData.data.forEach(d => {
          const vehicle = this.vehicles.findIndex(dev => dev.id === d.id);
          if (vehicle > -1) {

            this.calculateStatsAndEmit().then();

            const updateMarker = true;

            this.vehicles[vehicle].fix_time = d.fix_time ? d.fix_time : null;
            this.vehicles[vehicle].battery = d.battery ? d.battery : null;
            this.vehicles[vehicle].on_duty = d.on_duty ? d.on_duty : null;
            this.vehicles[vehicle].updated_on = d.updated_on ? d.updated_on : null;
            this.vehicles[vehicle].has_charging = d.has_charging ? d.has_charging : null;
            this.vehicles[vehicle].current_location.latitude = d.current_location.latitude ? d.current_location.latitude : null;
            this.vehicles[vehicle].current_location.longitude = d.current_location.longitude ? d.current_location.longitude : null;
            if (updateMarker) {
              this.vehiclesToUpdateMarkers.push(this.vehicles[vehicle]);
            }

          }
        });
        this.vehiclesSubject$.next({vehicles: this.vehicles, reEmitToMap: false });

        if (this.vehiclesToUpdateMarkers.length > 0) {
          this.vehiclesToUpdateMarkersSubject$.next({ vehicles: this.vehiclesToUpdateMarkers});
        }
      }

    } catch (e) {
      console.error(e);
    }

  }

  setDeviceFilter(list) {
   this.filterDeviceList = list;
   this.init();
  }

  setSelectedZones(list) {
    this.zoneList = list;
    this.zoneSelectedSubject$.next({zoneList: this.zoneList });
    this.init();
  }

  destroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
