import {Component, Input, OnInit} from '@angular/core';
import {DataService, ToastService} from '../../../@core/utils';
import {Router} from '@angular/router';
import {TranslatePipe,TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'ngx-merchant-list',
	templateUrl: './merchant-list.component.html',
	styleUrls: ['./merchant-list.component.scss'],
})
export class MerchantListComponent implements OnInit {

	@Input()
	serial: string = '1';
	@Input()
	users: any[] = [];
	@Input()
	outletId: string;

	constructor(private translate: TranslateService
,		private http: DataService, private toaster: ToastService, private router: Router) {
	}

	ngOnInit() {
	}
	translateText(key: string): string {
		let translation: string;
		this.translate.get(key).subscribe((res: string) => {
			translation = res;
		});
		return translation;
	}
	async addUser(event) {
		try {
			await this.http.create({
				__action: 'add',
				merchant_id: event.id,
				outlet_id: this.outletId,
			}, {}, 'merchant_outlet_association', 'auth');
			this.users.push(event);
			this.toaster.showToast('Successfully added Merchant.', 'Success', false);
		} catch (e) {
			this.toaster.showToast('Error adding Merchant ', 'Error', true, e);
		}
	}

	async removeUser(id: number, index: number) {
		try {
			await this.http.create({
				__action: 'remove',
				merchant_id: id,
				outlet_id: this.outletId,
			}, {}, 'merchant_outlet_association', 'auth');
			this.users.splice(index, 1);
			this.toaster.showToast('Successfully removed Merchant.', 'Success', false);
		} catch (e) {
			this.toaster.showToast('Error removing Merchant ', 'Error', true, e);
		}
	}

	editUser(id?: number) {
		this.router.navigate(['/pages/management/base/merchant/' + (id ? id.toString(10) : 'new')]);
	}
}
