import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Order } from '../../../@core/models';
import {NbComponentSize} from '@nebular/theme/components/component-size';
import {DataService} from '../../../@core/utils';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ngx-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {

  @Input()
  textAlign: string;

  @Input()
  header: string;

  @Input()
  body: string;

  @Input()
  bodyMargin: string;

  @Input()
  progressBarLength = 0;

  @Input()
  margin = '';

  @Input()
  progressBarText: string = null;

  @Input()
  progressBarFooterText: string = null;

  @Input()
  cardSize: NbComponentSize = null;

  @Input()
  progressBarColor = '';

  @Input()
  iconName = '';

  @Input()
  iconFill = '';

  @Input()
  headerBg = '';

  @Input()
  bodyBg = '';

  @Input()
  bodyColor = '';

  @Input()
  cardType = '';

  @Input()
	orders: Order[];

	@Input()
	order_no: string;

	@Input()
	orders_total: any;

	@Input()
	order_status: string;

    @Input()
	colorCodeUserId: any;

	@Input()
	merchant: any;

	@Input()
	statusCode: any;

	@Input()
    external_id: any;

    @Input()
    color: any;

	@Output()
    orderActionEmitter: EventEmitter<any> = new EventEmitter<any>();
    countryCode: any;

  constructor(private translate: TranslateService
    ,private http: DataService) { }

  async ngOnInit() {
      await this.paymentCode();
  }
  translateText(key: string): string {
		let translation: string;
		this.translate.get(key).subscribe((res: string) => {
			translation = res;
		});
		return translation;
	}
    async paymentCode() {
        try {
            this.countryCode = (await this.http.query({}, 'company', 'auth' )).data[0];
        } catch (e) {
        }
    }
}
