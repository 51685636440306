import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {ToastService} from '../../../@core/utils';
import {TranslateService} from "@ngx-translate/core";
import { Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
	selector: 'ngx-dialog',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
	@Input() title: string;
	@Input() body: string;
	@Input() type: string;
	@Input() scheduleId: string;
	@Input() name: string;
	@Input() data:any;
	tableData: any;

	constructor(protected ref: NbDialogRef<DialogComponent>,  private toast: ToastService,
				private translate: TranslateService,private router: Router) {
	}
	translateText(key: string): string {
		let translation: string;
		this.translate.get(key).subscribe((res: string) => {
			translation = res;
		});
		return translation;
	}
	dismiss() {
		this.ref.close();
	}
	dismissPermission(value) {
		this.ref.close(value);
	}

	reject(reason) {
		this.toast.showToast(this.translateText('Leave Rejected'), 'Rejected', true);
		this.ref.close({'status': 'reject', 'notes': reason});
	}

	approve(reason) {
		this.toast.showToast(this.translateText('Leave Approved'), 'Success', false);
		this.ref.close({'status': 'approve', 'notes': reason});
	}

	 async submit(name,scheduleId,type: string) {
		if(this.type==='copy'){
			type='copy'
			this.toast.showToast(this.translateText('Schedule Copied Successfully'), 'Success', false);
			await this.router.navigateByUrl('/pages/roster/new', { state: { scheduleId: scheduleId,type:type} });
            this.ref.close(name)
		}
		else{
		this.ref.close(name);
		}
	}
	changePriority(){

	}

	onDrop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.tableData, event.previousIndex, event.currentIndex);
	  }

	ngOnInit() {
		console.log(this.data,'122')
		 this.tableData=JSON.parse(JSON.stringify(this.data))
	}

}

