import {Injectable, OnDestroy} from '@angular/core';
import * as moment from 'moment';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {DataService} from '../../@core/utils';
import {BehaviorSubject, interval, Subscription} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RoutePositionService implements OnDestroy {
    unSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    boltTrackBaseURL = '';
    boltTrackAuthToken;
    sub: Subscription;
    public trip: any;
    selectedVehicle: any;
    private tripTimeline: any;

    constructor(private httpDirect: HttpClient, handler: HttpBackend, private http: DataService) {
        this.httpDirect = new HttpClient(handler);

    }


    initService() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        this.sub = interval(5000).subscribe(async () => {
            await this.fetchTripAndPosition();
        });

    }

    destroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }


    async fetchTripAndPosition() {
        try {
            this.tripTimeline = (await this.http.query({
                __trip_id__equal: this.trip.id,
                __order_by: ['sequence'],
            }, 'trip_timeline', 'route')).data;
            await this.getRiderPathCoords();
        } catch (e) {

        }
    }

    async fetchPathFromBoltTrack(imei, start, end) {
        // const path = 'reports/position_via_imei?imei=' + imei + '&start=' + start + '&end=' + end;
        // const request = this.httpDirect.get(
        //     environment[this.boltTrackBaseURL] + path, {
        //       headers: {
        //         Authorization: this.boltTrackAuthToken,
        //       },
        //     });
        const query = {
            __created_on__date_gte: start,
            __created_on__date_lte: end,
            __imei__equal: imei,
        };
        try {
            return await this.http.get('', query, 'vehicle_position_via_imei_from_bolt', 'event');
        } catch (e) {
            console.error(e);
        }
    }

    async getVehicleById(selectedDate: any) {
        console.log('vehicle called')
        let coords = [];
        try {
            if (this.selectedVehicle) {
                const imei = (await this.http.query({__id__equal: this.selectedVehicle}, 'vehicle', 'auth')).data[0].external_id;

                const response = await this.fetchPathFromBoltTrack(imei,
                    moment(selectedDate[0]).toJSON(), moment(selectedDate[1]).toJSON());

                coords = response['data'].filter(d => d.latitude && d.longitude).map(d => {
                    return {
                        'coords': [d.latitude, d.longitude],
                        'details': d,
                    };
                });
            } else {
                const response = await this.http.query({
                    __include: ['course'],
                    __rider_id__equal: this.selectedVehicle,
                    __fix_time__datetime_btw: [moment().subtract(16, 'seconds').toJSON(), moment().toJSON()],
                }, 'position', 'event');
                coords = response.data.filter(d => d.current_location.latitude && d.current_location.longitude).map(d => {
                    return [d.current_location.latitude, d.current_location.longitude];
                });
            }
        } catch (e) {

        }
        return coords;
    }

    async getRiderPathCoords() {
        let coords = [];
        try {
            if (this.boltTrackAuthToken && this.trip.vehicle_id) {
                const imei = (await this.http.query({__id__equal: this.trip.vehicle_id}, 'vehicle', 'auth')).data[0].external_id;
                const response = await this.fetchPathFromBoltTrack(imei,
                    moment().subtract(16, 'seconds').toJSON(), moment().toJSON());
                coords = response['data'].filter(d => d.latitude && d.longitude).map(d => {
                    return [d.latitude, d.longitude];
                });
            } else {
                const response = await this.http.query({
                    __include: ['course'],
                    __rider_id__equal: this.trip.rider_id,
                    __fix_time__datetime_btw: [moment().subtract(16, 'seconds').toJSON(), moment().toJSON()],
                }, 'position', 'event');
                coords = response.data.filter(d => d.current_location.latitude && d.current_location.longitude).map(d => {
                    return [d.current_location.latitude, d.current_location.longitude];
                });
            }
        } catch (e) {
            console.error(e);
        }
        return coords;
    }

    ngOnDestroy(): void {
        this.destroy();
    }


}
